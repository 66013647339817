import * as S from './styles';

import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import logoReanimateColors from '../../assets/images/v2/logo-re.animate-colors.png'
import logoReanimateColors2 from '../../assets/images/logo-re.animate-colors-2.png'
import iconSearch from '../../assets/images/v2/icon-search.png'

import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import ReactPaginate from 'react-paginate';
import ModalCompanyCreate from './Modal/create';

function Companies(props: any) {
  const { getCompanies } = useAuth()
  
  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [companies, setCompanies] = React.useState([])
  const [company, setCompany] = React.useState("")
  const [status, setStatus] = React.useState("")
  const [search, setSearch] = React.useState("")

  /* variáveis de paginação */
  const [last_page, setLastPage] = React.useState(0)
  const [show_modal, setShowModal] = React.useState(false)
  
  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    getCompanies({}).then(res => {
        setCompanies(res.data);

      /* seta variáveis de paginação */
      setLastPage(res.last_page)
    })
  },[])

  const changeStatus = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setStatus(val)

    let data = {};
    if(val !== ''){
      //@ts-ignore
      data['status'] = val;
    }

    if(search !== ''){
      //@ts-ignore
      data['search'] = search;
    }

    getCompanies(data).then(res => {
      setCompanies(res.data);
      setLastPage(res.last_page)
    })
  }

  const changeSearch = (e: any) => {
      let val = e;

      if(val === null)
        val = '';
      else
        if(typeof e.target !== 'undefined')
          val = e.target.value;

    setSearch(val)
  }

  const onSearch = (e: any) => {
    let data = {};
    if(status !== ''){
      //@ts-ignore
      data['status'] = status;
    }

    if(search !== ''){
      //@ts-ignore
      data['search'] = search;
    }

    getCompanies(data).then(res => {
      setCompanies(res.data);
      setLastPage(res.last_page)
    })
  }
  

  // Atualiza listagem a partir da paginação.
  const handlePageClick = (event: any) => {

    let page = event.selected + 1;

    let data = {"company" : props.match.params.uuid, 'page' : page};

    if(status !== ''){
      //@ts-ignore
      data['status'] = status;
    }

    if(search !== ''){
      //@ts-ignore
      data['search'] = search;
    }


    getCompanies(data).then(res => {
      setCompanies(res.data);
      setLastPage(res.last_page)
    })
  };

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const handleBack = () => {
    props.history.goBack()
  }

  return (
    <S.Container>

      <Menu />

      {show_modal === true &&
        <ModalCompanyCreate handleClose={hideModal} />
      }

      <Container fluid>
        <Row>
          <Col md={12}>

            <S.CompanyWrapper>

              <Container fluid>

                <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

                <Row>

                  <Col md={2}>

                    <h1 data-aos="fade-up" data-aos-duration="800">Empresas</h1>

                      <div className="text-start">
                        <Link to='#' className='btn-new-register' onClick={showModal}>
                          Cadastrar nova empresa
                        </Link>
                      </div>
                  </Col>

                  <Col md={10}>
                    <S.CompanyList data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                    <Row>
                      <Col md={12}>
                        <Form.Group className='form-filters text-end'>
                          <FormLabel className="form-label">Filtrar por</FormLabel>

                          <input name="status" type="hidden" value={status} />
                          <select onChange={e => changeStatus(e)}>
                            <option value="">Todas as empresas</option>
                            <option key={1} value={1}>Empresas ativas</option>
                          </select>

                          <FormLabel className="search-label">
                            <input name="search" value={search} onChange={e => changeSearch(e)} className='input-search' placeholder="Buscar empresa" type="text" />
                            <img src={iconSearch} onClick={e => onSearch(e)} />
                          </FormLabel>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        {companies?.map((company: any) => (
                          <Link to={`/company/${company?.uuid}`} key={company.id} >
                              <S.CompanyItem className={company.status !== 1 ? 'disabled' : ''}>
                                  {company?.file ?
                                    <img src={`https://api.reanimate.com.br/api/companies/image/${company?.uuid}`} />
                                    :
                                    <>{company?.sub === 1 &&
                                      <img src={logoReanimateColors2} />
                                    }
                                    </>
                                  }
                                  <p className='company-title'>{company?.title}</p>
                              </S.CompanyItem>
                          </Link>
                        ))}
                      </Col>
                    </Row>

                      {last_page > 1 &&
                        <Row>
                          <Col md={12}>
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel=">"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={last_page}
                              previousLabel="<"

                            />
                          </Col>
                        </Row>
                        }
                    </S.CompanyList>
                  </Col>
                </Row>

              </Container>

            </S.CompanyWrapper>
            

          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default Companies;
