import * as S from './styles';

import React, {
    useState,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'
import iconArrowLeft from '../../assets/images/icon-arrow-left-2.png'

import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

function CompanyDetail(props: any) {
  const { getCompanyDetail } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [title, setTitle] = useState("")
  const [unlimited, setUnlimited] = useState("0")
  const [parts_per_month, setPartsPerMonth] = useState("")
  const [total_parts, setTotalParts] = useState("0")
  const [total_parts_per_month, setTotalPartsPerMonth] = useState("0")
  const [additional_parts, setadditionalParts] = useState("0")
  const [balance, setbalance] = useState("0")
  const [tracker, setTracker] = useState([])

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    getCompanyDetail(props.match.params.uuid).then(res => {

      setTitle(res.title)
      setUnlimited(res.unlimited)
      setPartsPerMonth(res.parts_per_month)
      setTotalParts(res.total_parts)
      setTotalPartsPerMonth(res.total_parts_per_month)
      setadditionalParts(res.additional_parts)
      setbalance(res.balance)
      setTracker(res.trackerMonths)
    })
  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    return false;
  }

  const handleBack = () => {
    props.history.goBack()
  }
  
  return (
    <S.Container>

      <Menu />

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.CompanyWrapper>
              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <h1 data-aos="fade-up" data-aos-duration="800">{title}</h1>

              <img src={iconArrowLeft} onClick={handleBack} className="icon-arrow-left" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" />

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>

                    <S.CompanyForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={3}>

                            <Form.Group className="mt-4">
                              <FormLabel><b>Produto contratado</b></FormLabel>
                              <div>{unlimited == '1' ? 'Ilimitado' : 'Limitado' }</div>
                            </Form.Group>

                            {unlimited == '0' &&
                            <Form.Group className="mt-4">
                              <FormLabel><b>Peças por mês</b></FormLabel>
                              <div>{parts_per_month}</div>
                            </Form.Group>
                            }

                            <Form.Group className="mt-4">
                              <FormLabel><b>Total de peças criadas no mês</b></FormLabel>
                              <div>{total_parts_per_month}</div>
                            </Form.Group>

                            <Form.Group className="mt-4">
                              <FormLabel><b>Total de peças</b></FormLabel>
                              <div>{total_parts}</div>
                            </Form.Group>

                            {unlimited == '0' &&
                            <Form.Group className="mt-4">
                              <FormLabel><b>Saldo de peças adicionais</b></FormLabel>
                              <div>{additional_parts}</div>
                            </Form.Group>
                            }

                            {unlimited == '0' &&
                            <Form.Group className="mt-4">
                              <FormLabel><b>Saldo de peças no mês</b></FormLabel>
                              <div>{balance}</div>
                            </Form.Group>
                            }

                          </Col>

                          <Col md={12} lg={5}>

                            {tracker?.map((item: any) => (
                              <div key={item.month}>
                                <h4>{item.monthName}</h4>

                                {item.tracker?.map((itemTracker: any) => (
                                  <div key={itemTracker.type}>
                                    <h5>Peça: {itemTracker.type}</h5>
                                    <h5>Total: {itemTracker.total}</h5>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </Col>
                          
                          <Col md={12} lg={4}>                            

                          </Col>


                        </Row>
                      </Container>

                    </S.CompanyForm>



                  </Col>
                </Row>
              </Container>
            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default CompanyDetail;
