import styled from 'styled-components';

import iconMasterMotion from '../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../assets/images/icon-dynamic-ads.png'
import iconAutomailer from '../../assets/images/icon-automailer.png'

export const Container = styled.div`
    border-right: 10px #DD1B58 solid;

    .logo-reanimate-main {
        position: relative;
        margin-top: 133px;
    }

    .image-rocket {
        position: relative;
        margin-top: 80px;
    }

    .image-cloud-gray3 {
        position: absolute;
        left: 250px;
        top: 65%;
        opacity: .5;

        @media (max-width: 1100px) {
            display: none;
        }
    }

    @keyframes cloud_move {
        0% {
            left: 40%;
        }
    
        50% {
            left: calc(95% - 210px);
        }
        
        100% {
            left: 40%;
        }
    }

    .cloud_move {
        animation: cloud_move 50s linear infinite;
    }

    @keyframes cloud_move_2 {
        0% {
            right: 0;
        }
    
        50% {
            right: 20%;
        }
        
        100% {
            right: 0;
        }
    }

    .cloud_move_2 {
        animation: cloud_move_2 25s linear infinite;
    }

    @keyframes cloud_move_3 {
        0% {
            left: 20%;
        }
    
        50% {
            left: calc(50%);
        }
        
        100% {
            left: 20%;
        }
    }

    .cloud_move_3 {
        animation: cloud_move_3 30s linear infinite;
    }

    @keyframes rocket_move_1 {
        0% {
            margin-top: 380px;
        }
        
        20% {
            margin-top: 80px;
        }

        21% {
            margin-top: 82px;
        }
        22% {
            margin-top: 80px;
        }
        23% {
            margin-top: 82px;
        }
        24% {
            margin-top: 80px;
        }
        25% {
            margin-top: 82px;
        }
        26% {
            margin-top: 80px;
        }
        27% {
            margin-top: 82px;
        }
        28% {
            margin-top: 80px;
        }
        29% {
            margin-top: 82px;
        }
        30% {
            margin-top: 80px;
            opacity: 1;
        }
        31% {
            margin-top: 82px;
        }
        32% {
            margin-top: 80px;
        }
        33% {
            margin-top: 82px;
        }
        34% {
            margin-top: 80px;
        }
        35% {
            margin-top: 82px;
        }
        36% {
            margin-top: 80px;
        }
        37% {
            margin-top: 82px;
        }
        38% {
            margin-top: 80px;
        }
        39% {
            margin-top: 82px;
        }
        40% {
            margin-top: 80px;
        }
        41% {
            margin-top: 82px;
        }
        42% {
            margin-top: 80px;
        }
        43% {
            margin-top: 82px;
        }
        44% {
            margin-top: 80px;
        }
        45% {
            margin-top: 82px;
        }
        46% {
            margin-top: 80px;
        }
        47% {
            margin-top: 82px;
        }
        48% {
            margin-top: 80px;
        }
        49% {
            margin-top: 82px;
        }
        50% {
            margin-top: 80px;
        }
        51% {
            margin-top: 82px;
        }
        52% {
            margin-top: 80px;
        }
        53% {
            margin-top: 82px;
        }
        54% {
            margin-top: 80px;
        }
        55% {
            margin-top: 82px;
        }
        56% {
            margin-top: 80px;
        }
        57% {
            margin-top: 82px;
        }
        58% {
            margin-top: 80px;
        }
        59% {
            margin-top: 82px;
        }
        60% {
            margin-top: 80px;
        }
        61% {
            margin-top: 82px;
        }
        62% {
            margin-top: 80px;
        }
        63% {
            margin-top: 82px;
        }
        64% {
            margin-top: 80px;
        }
        65% {
            margin-top: 82px;
        }
        66% {
            margin-top: 80px;
        }
        67% {
            margin-top: 82px;
        }
        68% {
            margin-top: 80px;
        }
        69% {
            margin-top: 82px;
        }
        70% {
            margin-top: 80px;
        }
        71% {
            margin-top: 82px;
        }
        72% {
            margin-top: 80px;
        }
        73% {
            margin-top: 82px;
        }
        74% {
            margin-top: 80px;
        }
        75% {
            margin-top: 82px;
        }
        76% {
            margin-top: 80px;
        }
        77% {
            margin-top: 82px;
        }
        78% {
            margin-top: 80px;
        }
        79% {
            margin-top: 82px;
        }
        80% {
            margin-top: 80px;
        }
        81% {
            margin-top: 82px;
        }
        82% {
            margin-top: 80px;
        }
        83% {
            margin-top: 82px;
        }
        84% {
            margin-top: 80px;
        }
        85% {
            transform: scale(0.8);
            margin-top: 82px;
            /*opacity: 0;*/
        }

        86% {
            /*opacity: 0;*/
            transform: scale(1);
            /*margin-top: 480px;*/
        }

        100% {
            /*opacity: 1;*/
            margin-top: 380px;
        }
    }

    .rocket_move_1 {
        animation: rocket_move_1 10s ease-out infinite;
    }

    @keyframes rocket_move_2 {
        0% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
        }
    }

    .rocket_move_2 {
        animation: rocket_move_2 .6s linear;
    }

    @keyframes rocket_move_3 {
        0% {
            margin-top: 150px;
        }
        
        50% {
            margin-top: 80px;
        }

        100% {
            margin-top: 150px;
        }
    }

    .rocket_move_3 {
        animation: rocket_move_3 6s linear infinite, rocket_move_2 .8s linear;
    }


    .col-xxl-3 {
        @media (min-width: 1400px) and (max-width: 1800px) {
            width: 33.33333333% !important;
        }
    }



`;

export const ProductsContainer = styled.div`
    padding-top: 270px;

    @media (max-width: 991px) {
        padding-top: 160px;
    }

    h3 {
        margin: 0 0 30px;
        font-size: 65px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1.1;
        color: #000;
        opacity: 0.51;

        @media (max-width: 991px) {
            font-size: 40px;
            text-align: center;
        }

        @media (max-width: 500px) {
            font-size: 30px;
        }
    }

    p.description {
        margin: 0 0 60px;
        font-size: 25px;
        font-weight: 400;
        line-height: 1.1;
        color: #000;

        @media (max-width: 991px) {
            margin: 0 0 40px;
            font-size: 20px;
            text-align: center;
        }
    }

    .product-description {
        max-width: 290px;
        margin: 25px 0 25px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.3;
        color: #6C6C6C;

        @media (max-width: 1500px) {
            width: 290px;
            margin: 50px 0 20px;
        }
    
        @media (max-width: 1200px) {
            width: 270px;
        }

        @media (max-width: 991px) {
            margin: 20px auto 30px;
        }
    }

    .image-cloud-gray1 {
        position: absolute;
        right: 62px;
        opacity: .5;
        top: 40%;

        @media (max-width: 1500px) {
            right: 20px;
            width: 160px;
        }

        @media (max-width: 1100px) {
            display: none;
        }
    }

    .image-cloud-gray2 {
        position: absolute;
        right: 285px;
        top: 135px;

        @media (max-width: 991px) {
            right: 20px;
            top: 65px;
            width: 171px;
        }
    }
`;

export const ProductLink = styled.a`
    text-decoration: none !important;
`;

export const PreviewLink = styled.a`
    position: absolute;
    right: 70px;
    top: 35px;
    text-decoration: none !important;
`;

export const PreviewV2Link = styled.a`
    position: absolute;
    right: 75px;
    top: 35px;
    /*top: 70px;
    opacity: .3;*/
    text-decoration: none !important;
`;

export const ProductWrapper = styled.button`
    position: relative;
    width: 290px;
    height: 135px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F6F6F6 77%, #F5F5F5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 6px #0000001A;
    border: 3px solid #F2F2F2;
    border-radius: 20px;
    transition: 0.3s;

    p {
        margin: 0;
        font-family: 'AmpleSoft', sans-serif;
        font-size: 25px;
        font-weight: 700;
        text-align: left;
        text-transform: lowercase;
        padding: 0px 0px 0 105px;

        @media (max-width: 1500px) {
            font-size: 24px;
        }

        @media (max-width: 1199px) {
            font-size: 22px;
        }
    }

    &#master-motion {
        p {
            color: #009bdd;
        }
    }
    &#master-motion:after {
        display: block;
        position: absolute;
        left: 20px;
        top: 35px;
        content: " ";
        width: 65px;
        height: 65px;
        background-image: url(${iconMasterMotion});
    }

    &#replicate-ads {
        p {
            color: #7b4394;
        }
    }
    &#replicate-ads:after {
        display: block;
        position: absolute;
        left: 20px;
        top: 35px;
        content: " ";
        width: 65px;
        height: 65px;
        background-image: url(${iconReplicateAds});
    }

    &#dynamic-ads {
        p {
            color: #ff7000;
        }
    }
    &#dynamic-ads:after {
        display: block;
        position: absolute;
        left: 20px;
        top: 35px;
        content: " ";
        width: 65px;
        height: 65px;
        background-image: url(${iconDynamicAds});
    }

    &#automailer {
        p {
            color: #f3ba14;
        }
    }
    &#automailer:after {
        display: block;
        position: absolute;
        left: 20px;
        top: 35px;
        content: " ";
        width: 65px;
        height: 65px;
        background-image: url(${iconAutomailer});
    }    

    &:disabled {
        opacity: .3;
    }

    :hover:enabled{ 
        border: 3px solid #D1D1D1;
    }

    @media (max-width: 1500px) {
        width: 290px;
    }

    @media (max-width: 1200px) {
        width: 270px;
    }

    @media (max-width: 991px) {
        display: block;
        margin: auto;
    }
`;
