import * as S from './styles';

import React, {
    useState,
    useEffect,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'
import iconArrowLeft from '../../assets/images/icon-arrow-left-2.png'

import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Switch from "react-switch";

function User(props: any) {
  const { getUser, getListCompanies, getListRoles, getListProducts, updateUser } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [company, setCompany] = useState("")

  const [products, setproducts] = useState([])

  const [status, setStatus] = useState("0")
  const [uuid, setUUID] = useState("")

  const [listCompanies, setlistCompanies] = useState([])
  const [listRoles, setlistRoles] = useState([])
  const [listProducts, setlistProducts] = useState([])
  const [tracker, setTracker] = useState([])


  const changeName = (e: any) => {
      let val = e;

      if(val === null)
        val = '';
      else
        if(typeof e.target !== 'undefined')
          val = e.target.value;

    setName(val)
  }

  const changeEmail = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setEmail(val)
  }

  const changeRole = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setRole(val)
  }

  const changeCompany = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setCompany(val)
  }

  const changeStatus = (target: any) => {
    let status = target ? '1' : '0';
    setStatus(status)
  }
  
  const changeProducts = (e: any) => {
    if(e.target.checked === true)   
      e.target.classList.add("product-active");
    else
      e.target.classList.remove("product-active");

      var elementos = document.getElementsByClassName('product-active');

      let products: any = [];
      for (var i = 0; i < elementos.length; i++) {
        products.push(elementos[i].getAttribute('value'));
      }

      setproducts(products)
  }

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    getUser(props.match.params.uuid, {}).then(res => {

      //@ts-ignore
      res.user_products.forEach(element => {
        //@ts-ignore
        products.push(element.product.uuid);
      });
      setproducts(products)
      
      changeName(res.name)
      changeEmail(res.email)
      changeRole(res.user_role.title)
      changeCompany(res.user_companies[0].company.title)
      changeStatus(res.status)
      setUUID(res.uuid)
      setTracker(res.user_data)

      let company:any = [];
      if(user['user_role']['super'] === 1) {
        company = { company: res.user_companies[0].company.uuid }
      }  
      getListProducts(company).then(res => {
        setlistProducts(res)
      })

    })

    if(user['user_role']['super'] === 1) {
      getListCompanies().then(res => {
        setlistCompanies(res)
      })
    }


    getListRoles([]).then(res => {
      setlistRoles(res)
    })

  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {    
    //@ts-ignore
    data['products'] = products;
    //@ts-ignore
    delete data.user_company;
    //@ts-ignore
    delete data.user_role;
    //@ts-ignore
    if(data.password == '') {
      //@ts-ignore
      delete data.password;
      //@ts-ignore
      delete data.password_confirmation;
    }

    updateUser(data, uuid)
  }

  const handleBack = () => {
    props.history.goBack()
  }

  return (
    <S.Container>

      <Menu />

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.UserWrapper>
              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <h1 data-aos="fade-up" data-aos-duration="800">Usuário</h1>

              <img src={iconArrowLeft} onClick={handleBack} className="icon-arrow-left" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" />

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={7}>


                    <S.UserForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">


                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={8}>

                            <Form.Group>
                              <div className="company-title">{company}</div>
                              <Input name="user_company" placeholder="Empresa" type="hidden" value={company} disabled />
                            </Form.Group>

                            <Form.Group>
                              <Input name="name" placeholder="Nome" type="text" value={name} onChange={e => changeName(e)} />
                            </Form.Group>

                            <Form.Group>
                              <Input name="email" placeholder="Email" type="text" value={email} onChange={e => changeEmail(e)} />
                            </Form.Group>

                            <Form.Group>
                              <Input name="user_role" placeholder="Cargo" type="text" className="disabled" value={role} disabled />
                            </Form.Group>

                            <Form.Group>
                              <Input name="password" placeholder="Senha" type="password" />
                            </Form.Group>

                            <Form.Group>
                              <Input name="password_confirmation" placeholder="Confirmar Senha" type="password" />
                            </Form.Group>

                          </Col>

                          <Col md={12} lg={4}>
                            <h4>Acesso aos módulos</h4>

                            {listProducts?.map((product: any) => (
                              <div key={product.product_id} className="product-item">
                                <Input name="products[]" type="checkbox" className={
                                    //@ts-ignore
                                    (products.indexOf(product.product_uuid) > -1) ? 'product-active' : ''
                                  } 
                                  checked={
                                    //@ts-ignore
                                    products.indexOf(product.product_uuid) > -1 && product.company_product_status === 1
                                  }
                                  disabled={
                                    //@ts-ignore
                                    product.company_product_status === 0
                                  }
                                  value={product.product_uuid} onChange={e => changeProducts(e)} 
                                />

                                <label
                                   id={product.product_title.toLowerCase().replace(' ', '-')}
                                  className={
                                    //@ts-ignore
                                    (product.company_product_status === 0) ? 'disabled' : ''
                                  }
                                  >{product.product_title}</label>
                              </div>
                            ))}

                            <h4>Status do usuário</h4>

                            <Form.Group className="box-status">
                              <Switch
                                onChange={changeStatus}
                                checked={status == "1"}
                                className="react-switch"
                              />
                            </Form.Group>

                            <Form.Group className="d-none">
                              <label>Status</label><br />
                              ATIVO<Input name="status" placeholder="Sim" type="radio" value={status} checked={status == "1"}  onChange={e => changeStatus(1)}  />
                              INATIVO<Input name="status" placeholder="Não" type="radio" value={status} checked={status == "0"}  onChange={e => changeStatus(0)}  />
                            </Form.Group>

                            <Form.Group className="text-start">
                              <S.Button type="submit">
                                Enviar
                              </S.Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </S.UserForm>


                  </Col>


                  <Col md={12} lg={12} xl={5}>
                    <h4 className="mt-4">Peças gerados nos últimos 12 meses</h4>
                    
                    {tracker?.map((item: any) => {
                      
                      let type: any = [];

                      return (
                      <div key={item.month}>

                          <Form.Group className="mt-4">
                            <FormLabel className="text-capitalize"><b>{item.monthName}</b></FormLabel>
                          </Form.Group>

                        {item.products?.map((itemProduct: any) => {
                        
                        let classItem = 'tracker-container';

                        return (

                          <Form.Group key={itemProduct.product_id} className={`mt-1 ${classItem}`}>
                            <div className={`${itemProduct.product_title.toLowerCase().replace(' ', '-')}`}>{itemProduct.product_title}</div>

                            {itemProduct.tracker?.map((itemTracker: any) => {

                              return (
                              <div>{itemTracker.creative}: {itemTracker.total}</div>
                            )})}

                            {itemProduct.adjustments > 0 &&
                            <div>Ajustes: {itemProduct.adjustments}</div>
                            }
                          </Form.Group>

                        )})}
                      </div>
                    )})}
                  </Col>

                </Row>
              </Container>

            </S.UserWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default User;
