import * as S from './usersStyles';
import { Link } from 'react-router-dom';

import React, {
    useState,
    useEffect,
    useCallback,
    ChangeEvent
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPaginate from 'react-paginate';

import iconMasterMotionV2 from '../../../assets/images/v2/icon-master-motion-v2.png'
import iconReplicateAdsV2 from '../../../assets/images/v2/icon-replicate-ads-v2.png'
import iconHTML from '../../../assets/images/v2/icon-html.png'
import iconStatic from '../../../assets/images/v2/icon-static.png'
import iconVideo from '../../../assets/images/v2/icon-video.png'
import iconAdjustment from '../../../assets/images/v2/icon-adjustment.png'
import iconClose from '../../../assets/images/v2/icon-close.png'

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";

function ModalUsers(props: any) {
  const { getDashboardUsers } = useAuth()

  const token = useReadLocalStorage<[]>('@login-reanimate/token');

  const [users, setUsers] = React.useState([])
  const [last_page, setLastPage] = React.useState(0)

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    let data = {};

    if(props.company !== ''){
      //@ts-ignore
      data['company'] = props.company;
    }

    if(props.module !== ''){
      //@ts-ignore
      data['module'] = props.module;
    }

    if(props.date_start !== ''){
      //@ts-ignore
      data['date_start'] = props.date_start;
    }

    if(props.date_end !== ''){
      //@ts-ignore
      data['date_end'] = props.date_end;
    }

    getDashboardUsers(data).then(res => {
        setUsers(res.data);

      /* seta variáveis de paginação */
      setLastPage(res.last_page)
    })

  },[])

    // Atualiza listagem a partir da paginação.
    const handlePageClick = (event: any) => {

        let page = event.selected + 1;
        
        let data = {};

        //@ts-ignore
        data['page'] = page;

        if(props.company !== ''){
          //@ts-ignore
          data['company'] = props.company;
        }
    
        if(props.module !== ''){
          //@ts-ignore
          data['module'] = props.module;
        }
    
        if(props.date_start !== ''){
          //@ts-ignore
          data['date_start'] = props.date_start;
        }
    
        if(props.date_end !== ''){
          //@ts-ignore
          data['date_end'] = props.date_end;
        }
    
        getDashboardUsers(data).then(res => {
            setUsers(res.data);
    
          /* seta variáveis de paginação */
          setLastPage(res.last_page)
        })
      };


  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.CompanyWrapper>

              <img src={iconClose} className='icon-close' onClick={props.handleClose} />

              <h1 data-aos="fade-up" data-aos-duration="800">Usuários que mais criaram</h1>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>

                    <S.UserList>

                        <Container fluid className="p-0">

                        <Row>
                            <Col md={12} lg={12} xl={12}>

                            <div>
                                <Row>
                                    <Col md={4} lg={4} xl={4}>
                                        <h3>Todas as agências</h3>
                                    </Col>
                                    <Col md={2} lg={2} xl={2}>
                                        <h3>Módulos</h3>
                                    </Col>
                                    <Col md={5} lg={5} xl={5}>
                                        <h3>Tipo de peças</h3>
                                    </Col>
                                    <Col md={1} lg={1} xl={1}>
                                        <h3>Total</h3>
                                    </Col>
                                </Row>
                            </div>

                            <div className='user-list active'>

                            {users?.map((user: any) => {
                                return (
                                <div className={`user-list-item ${ user?.admin === 1 ? 'admin' : '' } `}>
                                <Row>
                                    <Col md={4} lg={4} xl={4}>
                                    <Link to='#' key={user.id} 
                                    className={user?.status === 0 ? 'line-through' : '' }
                                    >
                                        {user?.name} <strong>({user?.company_title})</strong>
                                    </Link>
                                    </Col>
                                    <Col md={2} lg={2} xl={2}>
                                        <Row className='total-parts'>
                                            
                                            {props.module == '' &&
                                            <Col md={6} lg={6} xl={6}>
                                            {user?.total_master_motion}
                                            <img src={iconMasterMotionV2} />
                                            </Col>
                                            }

                                            {props.module == '' &&
                                            <Col md={6} lg={6} xl={6}>
                                            {user?.total_replicate_ads}
                                            <img src={iconReplicateAdsV2} />
                                            </Col>
                                            }

                                            {props.module == '1' &&
                                            <Col md={6} lg={6} xl={6}>
                                            {user?.total_master_motion}
                                            <img src={iconMasterMotionV2} />
                                            </Col>
                                            }

                                            {props.module == '2' &&
                                            <Col md={6} lg={6} xl={6}>
                                            {user?.total_replicate_ads}
                                            <img src={iconReplicateAdsV2} />
                                            </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col md={5} lg={5} xl={5}>
                                        <Row className='total-parts'>
                                            <Col md={3} lg={3} xl={3}>
                                                {user?.total_html}
                                                <img src={iconHTML} />
                                            </Col>

                                            <Col md={3} lg={3} xl={3}>
                                                {user?.total_static}
                                                <img src={iconStatic} />
                                            </Col>

                                            <Col md={3} lg={3} xl={3}>
                                                {user?.total_adjustment}
                                                <img src={iconAdjustment} />
                                            </Col>

                                            <Col md={3} lg={3} xl={3}>
                                                {user?.total_video}
                                                <img src={iconVideo} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={1} lg={1} xl={1} className='text-end'>
                                        <Row className='total-parts'>
                                            <div>{user?.total}</div>
                                        </Row>
                                    </Col>
                                </Row>
                                </div>
                            )})}

                            </div>
                            </Col>
                        </Row>

                        </Container>

                    </S.UserList>

                    {last_page > 1 &&
                        <Row>
                        <Col md={12}>
                            <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={last_page}
                            previousLabel="<"

                            />
                        </Col>
                        </Row>
                    }


                  </Col>
                </Row>
              </Container>

            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalUsers;
