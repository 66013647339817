import * as S from './styles';

import React, {
    useState,
    useEffect,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'
import iconArrowLeft from '../../assets/images/icon-arrow-left-2.png'

import Menu from '../../components/Menu';
import { Link } from 'react-router-dom';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Switch from "react-switch";

function UserCreate(props: any) {
  const { getListCompanies, getListRoles, getListProducts, getCompany, sendUser } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [company, setCompany] = useState([])

  const [products, setproducts] = useState([])

  const [status, setStatus] = useState("1")

  const [listCompanies, setlistCompanies] = useState([])
  const [listRoles, setlistRoles] = useState([])
  const [listProducts, setlistProducts] = useState([])

  const [companyTitle, setCompanyTitle] = useState("")
  const [companyUUID, setCompanyUUID] = useState("")

  const changeName = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setName(val)
  }

  const changeEmail = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setEmail(val)
  }

  const changeRole = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setRole(val)
  }

  const changeCompany = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setCompany(val)
  }

  const changeStatus = (target: any) => {
    let status = target ? '1' : '0';
    setStatus(status)
  }
  
  const changeProducts = (e: any) => {
    if(e.target.checked === true)   
      e.target.classList.add("product-active");
    else
      e.target.classList.remove("product-active");

      var elementos = document.getElementsByClassName('product-active');

      let products: any = [];
      for (var i = 0; i < elementos.length; i++) {
        products.push(elementos[i].getAttribute('value'));
      }

      setproducts(products)
  }

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    if(user['user_role']['super'] === 1) {
      getListCompanies().then(res => {
        setlistCompanies(res)
      })
    }

    let company:any = [];
    if(user['user_role']['super'] === 1 && typeof props.match.params.uuid !== 'undefined') {
      company = { company: props.match.params.uuid }

      getCompany(props.match.params.uuid, {}).then(res => {
        setCompanyTitle(res.title)
        //setCompanyUUID(res.uuid)
      })
    }

    getListRoles(company).then(res => {
      setlistRoles(res)
    })

    if(user['user_role']['super'] === 0 && user['user_role']['admin'] === 1) {
      setCompanyTitle(user.user_companies[0].company.title)
    }

    getListProducts(company).then(res => {
      setlistProducts(res)
    })

    //user.user_companies[0].company.uuid
    //user.user_companies[0].company.title

  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {    
    //@ts-ignore
    data['products'] = products;

    sendUser(data)
  }

  const handleBack = () => {
    props.history.goBack()
  }

  return (
    <S.Container>

      <Menu />

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.UserWrapper>
              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <h1 data-aos="fade-up" data-aos-duration="800">Usuário</h1>

              <img src={iconArrowLeft} onClick={handleBack} className="icon-arrow-left" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" />

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={7}>

                    <S.UserForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={8}>

                            <Form.Group>
                              <div className="company-title">{companyTitle}</div>
                            </Form.Group>

                            <Form.Group>
                              <Input name="name" placeholder="Nome" type="text" value={name} onChange={e => changeName(e)} />
                            </Form.Group>

                            <Form.Group>
                              <Input name="email" placeholder="Email" type="text" value={email} onChange={e => changeEmail(e)} />
                            </Form.Group>

                            <Form.Group>
                              <Input name="role" placeholder="Cargo" type="hidden" value={role} />
                              <select onChange={e => changeRole(e)} >
                                <option value="">Cargo</option>
                                {listRoles?.map((item: any) => (
                                  <option key={item.id} value={item.uuid}>{item.title}</option>
                                ))}
                              </select>

                              {user['user_role']['super'] === 1 &&
                                <>
                                {typeof props.match.params.uuid !== 'undefined' ?
                                    <Input name="company" placeholder="Cargo" type="hidden" value={props.match.params.uuid} />
                                    :
                                    <>
                                    <Input name="company" placeholder="Cargo" type="hidden" value={company} />
                                    <select onChange={e => changeCompany(e)} >
                                      <option value="">Empresa</option>
                                      {listCompanies?.map((item: any) => (
                                        <option key={item.id} value={item.uuid}>{item.title}</option>
                                      ))}
                                    </select>
                                    </>
                                }
                                </>
                              }
                              {user['user_role']['super'] === 0 && user['user_role']['admin'] === 1 &&
                                  <Input name="company" placeholder="Cargo" type="hidden" value={user.user_companies[0].company.uuid} />
                              }
                            </Form.Group>

                            <Form.Group>
                              <Input name="password" placeholder="Senha" type="password" />
                            </Form.Group>

                            <Form.Group>
                              <Input name="password_confirmation" placeholder="Confirmar Senha" type="password" />
                            </Form.Group>

                          </Col>

                          <Col md={12} lg={4}>
                            <h4>Acesso aos módulos</h4>

                            {listProducts?.map((product: any) => (
                              <div key={product.product_id} className="product-item">
                                <Input name="products[]" type="checkbox" 
                                  checked={
                                    //@ts-ignore
                                    products.indexOf(product.product_uuid) > -1
                                  }
                                  disabled={
                                    //@ts-ignore
                                    product.company_product_status === 0
                                  }
                                  value={product.product_uuid} onChange={e => changeProducts(e)} 
                                />

                                <label
                                  id={product.product_title.toLowerCase().replace(' ', '-')}
                                  className={
                                    //@ts-ignore
                                    (product.company_product_status === 0) ? 'disabled' : ''
                                  }
                                  >{product.product_title}</label>
                              </div>
                            ))}

                            <h4>Status do usuário</h4>

                            <Form.Group className="box-status">
                              <Switch
                                onChange={changeStatus}
                                checked={status == "1"}
                                className="react-switch"
                              />
                            </Form.Group>

                            <Form.Group className="d-none">
                              <label>Status</label><br />
                              ATIVO<Input name="status" placeholder="Sim" type="radio" value={status} checked={status == "1"}  onChange={e => changeStatus(1)}  />
                              INATIVO<Input name="status" placeholder="Não" type="radio" value={status} checked={status == "0"}  onChange={e => changeStatus(0)}  />
                            </Form.Group>

                            <Form.Group className="text-start">
                              <S.Button type="submit">
                              Enviar
                              </S.Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>

                    </S.UserForm>

                  </Col>
                </Row>
              </Container>


            </S.UserWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default UserCreate;
