import styled from 'styled-components';
import { Form as F } from '@unform/web'

import iconArrowRight from '../../assets/images/icon-arrow-right.png'
import iconArrowRight2 from '../../assets/images/icon-arrow-right-2.png'
import iconPlus from '../../assets/images/icon-plus.png'
import iconMasterMotion from '../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../assets/images/icon-dynamic-ads.png'

export const Container = styled.div`
    border-right: 10px #DD1B58 solid;
    min-height: 1000px;

    a {
        text-decoration: none !important;
    }
`;

export const UserWrapper = styled.div`
    position: relative;
    padding: 90px 20px 0;

    .logo-reanimate-colors {
        position: absolute;
        left: 50%;
        margin-left: -67px;
        /*transform: translateX(-50%);*/
        top: 19px;
        width: 136px;
        height: auto;

        @media (max-width: 991px) {
            left: inherit;
            margin-left: 0;
            right: 80px;
            left: inherit;
            transform: inherit;
        }
    }

    h1 {
        margin: 0;
        font-size: 65px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #DC1A59;

        @media (max-width: 991px) {
            font-size: 40px;
        }
    }

    h4 {
        margin: 0 0 20px;
        font-size: 20px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #000;
    }
`;

export const UserForm = styled(F)`

    input[type="text"],
    input[type="password"],
    select,
    textarea {
        width: 100%;
        height: 51px;

        border: 1px solid #707070;
        border-radius: 15px;

        margin: 0 0 15px;
        padding: 0 20px;
        background: transparent;
        outline: none;
        font-size: 20px;
        font-weight: 400;
        color: #000;

        &.disabled {
            opacity: .3;
        }

        ::placeholder {
            opacity: .5;
            color: #000;
            font-weight: 400;
        }
    }

    textarea {
        padding: 20px 20px;
        height: 210px;
    }

    label {
        margin-left: 10px;
    }

    #btn-send:disabled  {
        opacity: .3;
    }

`;

export const Button = styled.button`
    position: relative;
    margin: 20px 0 0;
    width: 206px;
    height: 51px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-dd1b58) 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #DD1B58 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: none;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;

    &::after {
        display: block;
        position: absolute;
        right: 18px;
        top: 18px;
        content: " ";
        width: 10px;
        height: 16px;
        background-image: url(${iconArrowRight2});
    }

`;


export const Regulation = styled.div`
    position: relative;
    padding: 10px 0;
    height: 300px;
    overflow: auto;
`;