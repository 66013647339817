import * as S from './companiesStyles';

import React, {
    useState,
    useEffect,
    useCallback,
    ChangeEvent
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import iconClose from '../../../assets/images/v2/icon-close.png'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";

function ModalCompanies(props: any) {
  const { getDashboardCompanies } = useAuth()

  const token = useReadLocalStorage<[]>('@login-reanimate/token');

  const dataTest = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };

  const [data_companies, setDataCompanies] = useState(dataTest)

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    let data = {};

    if(props.company !== ''){
      //@ts-ignore
      data['company'] = props.company;
    }

    if(props.module !== ''){
      //@ts-ignore
      data['module'] = props.module;
    }

    if(props.date_start !== ''){
      //@ts-ignore
      data['date_start'] = props.date_start;
    }

    if(props.date_end !== ''){
      //@ts-ignore
      data['date_end'] = props.date_end;
    }

    getDashboardCompanies(data).then(res => {
      addCompaniesChart(res)
    })


  },[])


  const addCompaniesChart = (data: any) => {
    
    const labelsChart: any = [];
    const dataChart: any = [];
    const backgroundColor = ['rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)','rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)','rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)','rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)'];
    const borderColor = ['rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)','rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)','rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)','rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)'];

    data?.map((item: any) => {
      labelsChart.push(item.company_title)
      dataChart.push(item.total)
    })

    const chart = {
      labels: labelsChart,
      datasets: [
        {
          label: '# total de peças',
          data: dataChart,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    //@ts-ignore
    setDataCompanies(chart);
  }

  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.CompanyWrapper>

              <img src={iconClose} className='icon-close' onClick={props.handleClose} />

              <h1 data-aos="fade-up" data-aos-duration="800">Ranking de agências <span>(Total de peças)</span></h1>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>


                    <div className={`companies-graph`}>
                      <Doughnut data={data_companies} />
                    </div>

                  </Col>
                </Row>
              </Container>

            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalCompanies;
