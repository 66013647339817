import * as S from './styles';

import React, {
    useState,
    useEffect,
    useCallback,
    ChangeEvent
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import logoReanimateColors from '../../assets/images/v2/logo-re.animate-colors.png'
import iconMasterMotionV2 from '../../assets/images/v2/icon-master-motion-v2.png'
import iconReplicateAdsV2 from '../../assets/images/v2/icon-replicate-ads-v2.png'
import iconEdit from '../../assets/images/v2/icon-edit.png'
import iconMask from '../../assets/images/v2/icon-mask.png'
import iconMaskRed from '../../assets/images/v2/icon-mask-red.png'
import iconDelete from '../../assets/images/v2/icon-delete.png'
import iconArrowLeft from '../../assets/images/v2/icon-arrow-left.png'

import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../components/Input'
import Textarea from '../../components/Textarea'
import Switch from "react-switch";

import ModalUserCreate from './Modal/createUser';
import ModalUserDelete from './Modal/deleteUser';
import ModalUserUpdate from './Modal/updateUser';
import ModalCompanyUpdate from './Modal/updateCompany';

function Company(props: any) {
  const { getCompany, getListProducts, updateCompany, getUsers, sendCompanyParts, updateStatusUser } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [status, setStatus] = useState("0")
  const [uuid, setUUID] = useState("")
  const [listProducts, setlistProducts] = useState([])
  const [products, setproducts] = useState([])
  const [users, setUsers] = React.useState([])
  const [unlimited, setUnlimited] = useState("0")
  const [parts_per_month, setPartsPerMonth] = useState("")
  const [total_parts, setTotalParts] = useState("0")
  const [total_parts_per_month, setTotalPartsPerMonth] = useState("0")
  const [additional_parts, setadditionalParts] = useState("0")
  const [balance, setbalance] = useState("0")
  const [trackerMonth, setTrackerMonth] = useState([])
  const [tracker, setTracker] = useState([])
  const [parts_extra, setPartsExtra] = useState("")
  const [image, setImage] = useState(null)
  const [date_start, setDateStart] = useState("")
  const [date_end, setDateEnd] = useState("")
  const [userUUID, setUserUUID] = React.useState('')

  const [sub, setSub] = React.useState("0")
  const [unlimited_users, setUnlimitedUsers] = React.useState("0")
  const [total_users, setTotalUsers] = React.useState("0")
  const [total_registered_users, setTotalRegisteredUsers] = React.useState("0")
  const [register_users, setRegisterUsers] = React.useState(false)

  const [module, setModule] = React.useState("")
  const [months, setMonths] = React.useState("")

  const [show_modal, setShowModal] = React.useState(false)
  const [show_modal_delete, setShowModalDelete] = React.useState(false)
  const [user_delete, setUserDelete] = React.useState([])

  const [show_modal_update, setShowModalUpdate] = React.useState(false)
  const [show_modal_company, setShowModalCompanyUpdate] = React.useState(false)

  const [user_update, setUserUpdate] = React.useState([])

  const changeTitle = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setTitle(val)
  }

  const changeDescription = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDescription(val)
  }

  const changeStatus = (target: any) => {
    let status = target ? '1' : '0';
    setStatus(status)
  }

  const changeUnlimited = (target: any) => {
    let unlimited = target ? '1' : '0';
    setUnlimited(unlimited)
  }

  const changePartsPerMonth = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setPartsPerMonth(val)
  }  

  const changeTotalParts = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setTotalParts(val)
  }

  const changeTotalPartsPerMonth = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setTotalPartsPerMonth(val)
  }

  const changePartsExtra = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

        setPartsExtra(val)
  }

  const changeProducts = (e: any) => {
    if(e.target.checked === true)   
      e.target.classList.add("product-active");
    else
      e.target.classList.remove("product-active");

      var elementos = document.getElementsByClassName('product-active');

      let products: any = [];
      for (var i = 0; i < elementos.length; i++) {
        products.push(elementos[i].getAttribute('value'));
      }

      setproducts(products)
  }

  const changeModule = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setModule(val)

    let data = {};
    if(val !== ''){
      //@ts-ignore
      data['module'] = val;
    }

    if(months !== ''){
      //@ts-ignore
      data['months'] = months;
    }

    if(date_start !== ''){
      //@ts-ignore
      data['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      data['date_end'] = date_end;
    }

    getCompany(props.match.params.uuid, data).then(res => {
      changeTotalParts(res.total_parts)
      setTracker(res.tracker)
    })

    getUsers({"company" : props.match.params.uuid, ...data}).then(res => {
      setUsers(res.users);
      setUserUUID(res.uuid);
    })

  }

  const changeMonths = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setMonths(val)

    let data = {};
    if(module !== ''){
      //@ts-ignore
      data['module'] = module;
    }

    if(val !== ''){
      //@ts-ignore
      data['months'] = val;
    }

    getCompany(props.match.params.uuid, data).then(res => {
      changeTotalParts(res.total_parts)
      setTracker(res.tracker)
    })
    

  }

  const handleSearch = () => {

    let data = {};
    if(module !== ''){
      //@ts-ignore
      data['module'] = module;
    }

    if(months !== ''){
      //@ts-ignore
      data['months'] = months;
    }

    if(date_start !== ''){
      //@ts-ignore
      data['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      data['date_end'] = date_end;
    }

    getCompany(props.match.params.uuid, data).then(res => {
      changeTotalParts(res.total_parts)
      setTracker(res.tracker)
    })

    getUsers({"company" : props.match.params.uuid, ...data}).then(res => {
      setUsers(res.users);
      setUserUUID(res.uuid);
    })

  }

  const changeDateStart = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDateStart(val)
  }

  const changeDateEnd = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDateEnd(val)
  }

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    getCompany(props.match.params.uuid, {}).then(res => {

      //@ts-ignore
      res.company_product.forEach(element => {
        //@ts-ignore
        products.push(element.product.uuid);
      });
      setproducts(products)

      changeTitle(res.title)
      changeDescription(res.description)
      changeStatus(res.status)
      setUUID(res.uuid)
      changeUnlimited(res.unlimited)
      changePartsPerMonth(res.parts_per_month)
      changeTotalParts(res.total_parts)
      changeTotalPartsPerMonth(res.total_parts_per_month)

      setadditionalParts(res.additional_parts)
      setbalance(res.balance)
      setTrackerMonth(res.trackerMonths)
      setTracker(res.tracker)

      setSub(res.sub)
      setUnlimitedUsers(res.unlimited_users)
      setTotalUsers(res.total_users)
      setTotalRegisteredUsers(res.total_registered_users)
      setRegisterUsers(res.register_users)
    })

    getListProducts([]).then(res => {
      setlistProducts(res)
    })

    getUsers({"company" : props.match.params.uuid}).then(res => {
      setUsers(res.users);
      setUserUUID(res.uuid);
    })

  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {

      if(key !== 'products') {
        //@ts-ignore
        formData.append(key, data[key]);
      }

    }

    for (let i = 0; i < products.length; i++) {
      //@ts-ignore
      formData.append("products[]", products[i]);
    }

    if (image !== null) {
      //@ts-ignore
      formData.append("files", image);
    }

    updateCompany(formData, uuid)
  }

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    
    if (!file) {
      setImage(null);
    }

    //@ts-ignore
    setImage(file);
    
  }, []);

  const handleAddParts = () => {
    sendCompanyParts({"company" : uuid, 'amount' : parts_extra})
  }

  function handleClick(event: any){
    event.currentTarget.parentElement.classList.toggle('active');
  }

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const showModalDelete = (user: any) => {
    setShowModalDelete(true);
    setUserDelete(user);
  };

  const hideModalDelete = () => {
    setShowModalDelete(false);
    setUserDelete([]);
  };

  const showModalUpdate = (user: any) => {
    setShowModalUpdate(true);
    setUserUpdate(user);
  };

  const hideModalUpdate = () => {
    setShowModalUpdate(false);
    setUserUpdate([]);
  };

  const showModalCompanyUpdate = (user: any) => {
    setShowModalCompanyUpdate(true);
  };

  const hideModalCompanyUpdate = () => {
    setShowModalCompanyUpdate(false);
  };

  const handleBack = () => {
    props.history.goBack()
  }
  
  return (
    <S.Container>

      <Menu />

      {show_modal === true &&
        <ModalUserCreate handleClose={hideModal} uuid={props.match.params.uuid} />
      }

      {show_modal_delete === true &&
        <ModalUserDelete handleClose={hideModalDelete} userDelete={user_delete} />
      }

      {show_modal_update === true &&
      <ModalUserUpdate handleClose={hideModalUpdate} userUpdate={user_update} showModalDelete={showModalDelete} uuid={props.match.params.uuid} months={months} date_start={date_start} date_end={date_end} />
      }

      {show_modal_company === true &&
      <ModalCompanyUpdate handleClose={hideModalCompanyUpdate} title={title} description={description} uuid={props.match.params.uuid} />
      }

      <Container fluid>

        <Row>

          <Col md={12}>

            <S.CompanyWrapper>

              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <Container fluid className="p-0">
                <Row>

                  <Col md={12} lg={12} xl={12}>

                    <S.CompanyForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Input name="_method" type="hidden" value="PUT" />

                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={3}>

                            <h1 data-aos="fade-up" data-aos-duration="800">
                              <Link to='/companies' >
                                <img src={iconArrowLeft} />
                              </Link>
                              <span onClick={showModalCompanyUpdate} >{title}</span>
                            </h1>

                            <h4 className="mt-5">Status de uso:</h4>

                            <Form.Group className="box-switch box-status">
                              <Switch
                                onChange={changeStatus}
                                checked={status == "1"}
                                className="react-switch"
                              />
                              <span>Ativo</span>
                            </Form.Group>

                            <Form.Group className="d-none">
                              ATIVO<Input name="status" placeholder="Sim" type="radio" value={status} checked={status == "1"}  onChange={e => changeStatus(1)}  />
                              INATIVO<Input name="status" placeholder="Não" type="radio" value={status} checked={status == "0"}  onChange={e => changeStatus(0)}  />
                            </Form.Group>

                            <h4 className="mt-2">Módulos ativos:</h4>

                            {listProducts?.map((product: any) => (
                            <div key={product.product_id} className="product-item">
                              <Input name="products[]" type="checkbox" className={
                                  //@ts-ignore
                                  (products.indexOf(product.product_uuid) > -1) ? 'product-active' : ''
                                } 
                                checked={
                                  //@ts-ignore
                                  products.indexOf(product.product_uuid) > -1
                                } 
                                value={product.product_uuid} onChange={e => changeProducts(e)} 
                              />

                              <label id={product.product_title.toLowerCase().replace(' ', '-')}>{product.product_title}</label>
                            </div>
                            ))}

                            <h4 className="mt-5">Tipo de plano</h4>

                            <Form.Group className="box-switch box-unlimited">
                              <Switch
                                onChange={changeUnlimited}
                                checked={unlimited == "1"}
                                className="react-switch"
                              />
                              <span>Peças ilimitadas</span>
                            </Form.Group>

                            <Form.Group className="d-none">
                              ATIVO<Input name="unlimited" placeholder="Sim" type="radio" value={unlimited} checked={unlimited == "1"}  onChange={e => changeUnlimited(1)}  />
                              INATIVO<Input name="unlimited" placeholder="Não" type="radio" value={unlimited} checked={unlimited == "0"}  onChange={e => changeUnlimited(0)}  />
                            </Form.Group>

                            {unlimited == '0' &&
                            <Form.Group className="mt-4 group-left">
                              <FormLabel>Peças por mês</FormLabel><br />

                              <Input name="parts_per_month" placeholder="Peças por mês" type="text" value={parts_per_month} onChange={e => changePartsPerMonth(e)} />
                            </Form.Group>
                            }

                            {unlimited == '0' &&
                            <Form.Group className="">
                              <FormLabel className="label-gray">Utilizadas: <b>{total_parts_per_month}</b></FormLabel><br />
                              <FormLabel className="label-gray">Restam: <b>{balance}</b></FormLabel>
                            </Form.Group>
                            }

                            {unlimited == '0' &&
                            <Form.Group className="mt-4 group-left">
                              <FormLabel>Peças adicionais</FormLabel><br />
                              <Input className="mb-1" name="parts_extra" placeholder="" type="text" value={parts_extra} onChange={e => changePartsExtra(e)} /><br />
                              <FormLabel className="mt-0 mb-3 label-gray">Peças adicionais: <b>{additional_parts}</b></FormLabel>
                            </Form.Group>
                            }

                            {unlimited == '0' &&
                            <Form.Group className="text-start mb-4">
                              <div onClick={handleAddParts}>
                                <S.ButtonSmall type="button" >
                                  Adicionar
                                </S.ButtonSmall>
                              </div>
                            </Form.Group>
                            }

                            <Form.Group className="mt-1">
                              <FormLabel className='label-gray info'>Plano de assinatura: <b>{(sub == '1') ? 'Sim' : 'Não'}</b></FormLabel>
                            </Form.Group>

                            {(sub == '1' && unlimited_users == '0') &&
                            <Form.Group className="mt-1">
                              <FormLabel className='label-gray info'>Total de usuários contratados: <b>{total_users}</b></FormLabel><br />
                              <FormLabel className='label-gray info'>Total de usuários cadastros: <b>{total_registered_users}</b></FormLabel>
                            </Form.Group>
                            }

                            <Form.Group className="text-start mb-4">
                              <S.Button type="submit">
                                Salvar
                              </S.Button>
                            </Form.Group>

                          </Col>

                          <Col md={12} lg={9}>

                            <Row>
                              <Col md={12} lg={9}>

                                <Form.Group className='form-filters'>
                                  <FormLabel className="form-label">Filtrar por</FormLabel>

                                  <input name="module" type="hidden" value={module} />
                                  <select onChange={e => changeModule(e)}>
                                    <option value="">Todos os módulos</option>
                                    <option key={1} value='1'>Master Motion</option>
                                    <option key={1} value='2'>Replicate Ads</option>
                                  </select>

                                  {/*
                                  <input name="months" type="hidden" value={months} />
                                  <select onChange={e => changeMonths(e)}>
                                    <option value="">Geral</option>
                                    <option value="12">Últimos 12 meses</option>
                                  </select>
                                  */}

                                  <Input name="date_start" type="date" value={date_start} onChange={e => changeDateStart(e)} />
                                  <Input name="date_end" type="date" value={date_end} onChange={e => changeDateEnd(e)} />
                                  <div className='btn-Search' onClick={handleSearch}>Pesquisa</div>
                                </Form.Group>

                              </Col>

                              <Col md={12} lg={3}>

                                {register_users == true &&
                                <Form.Group className="text-end">
                                  <Link to='#' onClick={showModal} >
                                    <p className='btn-new-register'>Adicionar usuário</p>
                                  </Link>
                                </Form.Group>
                                }

                              </Col>
                            </Row>

                            <Row>

                              <Col md={12} lg={4}>
                              
                              {tracker?.map((item: any) => {
                                  
                                  let totalItens: any = 0;
        
                                  return (
                                  <div className={`${item.product_title.toLowerCase().replace(' ', '-')} tracker-container`}>
    
                                    <h3>{item.product_title}</h3>
    
                                    {item.tracker?.map((itemTracker: any) => {
    
                                      totalItens += itemTracker.total;
    
                                      return (
                                      <p>{itemTracker.creative} <span>{itemTracker.total}</span></p>
                                    )})}
    
                                    {item.adjustments > 0 &&
                                    <p>Ajustes <span>{item.adjustments}</span></p>
                                    }
    
                                    <hr />
    
                                    <p className="total">Total <span>{totalItens}</span></p>
    
                                  </div>
                                )})}
    
                                <Form.Group>
                                  <FormLabel className="general-total">Total geral <span>{total_parts}</span></FormLabel>
                                </Form.Group>
    
                              </Col>

                              <Col md={12} lg={8}>                                

                                <S.UserList>

                                  <Container fluid className="p-0">
                                    <Row>
                                      <Col md={6} lg={6} xl={6}>
                                        <h4>Usuários que mais criaram</h4>
                                      </Col>
                                      <Col md={6} lg={6} xl={6}>
                                        <h5>Peças</h5>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={12} lg={12} xl={12}>
                                      {users?.map((team: any) => (
                                        <div className='user-list active'>
                                          <h3 onClick={handleClick}>
                                            { team?.team !== null ? team?.team : 'Padrão' }
                                          </h3>
                                          {team.users?.map((user: any) => {
                                            return (
                                            <div className={`user-list-item ${ user?.admin === 1 ? 'admin' : '' } `}>
                                              <Row>
                                                <Col md={6} lg={6} xl={6}>

                                                {userUUID !== user.uuid ?
                                                  <Link to='#' key={user.id} 
                                                  className={user?.status === 0 ? 'line-through' : '' }
                                                  onClick={() => showModalUpdate(user)}
                                                  >
                                                    {user?.name}
                                                  </Link>
                                                  :
                                                  <span className="opacity-50">{user?.name}</span>
                                                  }

                                                </Col>
                                                <Col md={6} lg={6} xl={6}>
                                                  <Row className='total-parts'>
                                                    
                                                   {module == '' &&
                                                    <Col md={3} lg={3} xl={3}>
                                                      {user?.total_master_motion}
                                                      <img src={iconMasterMotionV2} />
                                                    </Col>
                                                    }

                                                    {module == '' &&
                                                    <Col md={3} lg={3} xl={3}>
                                                      {user?.total_replicate_ads}
                                                      <img src={iconReplicateAdsV2} />
                                                    </Col>
                                                    }

                                                    {module == '1' &&
                                                    <Col md={3} lg={3} xl={3}>
                                                      {user?.total_master_motion}
                                                      <img src={iconMasterMotionV2} />
                                                    </Col>
                                                    }

                                                    {module == '2' &&
                                                    <Col md={3} lg={3} xl={3}>
                                                      {user?.total_replicate_ads}
                                                      <img src={iconReplicateAdsV2} />
                                                    </Col>
                                                    }

                                                    <Col md={6} lg={6} xl={6}>
                                                      {userUUID !== user.uuid &&
                                                        <div className='actions'>
                                                          <img src={iconEdit} onClick={() => showModalUpdate(user)} />
                                                          {user?.status === 1 ?
                                                            <img src={iconMask} onClick={() => updateStatusUser(user.uuid)} />
                                                            :
                                                            <img src={iconMaskRed} onClick={() => updateStatusUser(user.uuid)} />
                                                          }
                                                          <img src={iconDelete} onClick={() => showModalDelete(user)} />
                                                          
                                                        </div>
                                                      }
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </div>
                                          )})}
                                        </div>
                                        ))}
                                      </Col>
                                    </Row>

                                  </Container>

                                </S.UserList>
                              </Col>

                            </Row>
                          </Col>


                        </Row>
                      </Container>

                    </S.CompanyForm>



                  </Col>
                </Row>
              </Container>
            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default Company;
