import api from '../services/api'

export async function signIn(data: any) {
  if (data) {
    try {
      const response = await api.post('/login', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function signInWithGoogle(data: any) {

  if (data) {
    try {
      const response = await api.post('/auth/social/callback', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function logout() {
  try {
    const response = await api.post('/logout')

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getUserProducts() {
  try {
    const response = await api.post('/products')

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function resetPassword(data: any) {
  if (data) {
    try {
      const response = await api.post('/reset/password', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function forgotPassword(data: any) {
  if (data) {
    try {
      const response = await api.post('/forgot/password', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function sendContact(data: any) {
  if (data) {
    try {
      const response = await api.post('/contact', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function getCompanies(data: any = []) {
  try {
    const response = await api.get('/companies', { params: data })

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getCompany(uuid: any, data: any = []) {
  if (uuid) {
    try {
      const response = await api.get('/companies/'+uuid, { params: data })

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function getCompanyDetail(data: any) {
  if (data) {
    try {
      const response = await api.get('/companies/detail/'+data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function getCompanyTeams(data: any, uuid: any) {
  if (data) {
    try {
      const response = await api.post('/companies/teams/'+uuid, data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function addCompanyTeam(data: any, uuid: any) {
  if (data) {

    try {
      const response = await api.post('companies/add/team/'+uuid, data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function updateCompany(data: any, uuid: any) {
  if (data) {
    try {
      const response = await api.post('/companies/'+uuid, data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function updateCompanyImage(data: any, uuid: any) {
  if (data) {
    try {
      const response = await api.post('/companies/update/image/'+uuid, data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function sendCompanyParts(data: any) {
  if (data) {
    try {
      const response = await api.post('/companies/parts', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function sendCompany(data: any) {
  if (data) {
    try {
      const response = await api.post('/companies', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function getUsers(data: any = []) {
  try {
    const response = await api.get('/users', { params: data })
    
    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getUser(uuid: any, data: any = []) {
  if (uuid) {
    try {
      const response = await api.get('/users/'+uuid, { params: data })

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function updateUser(data: any, uuid: any) {
  if (data) {
    try {
      const response = await api.put('/users/'+uuid, data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function sendUser(data: any) {
  if (data) {
    try {
      const response = await api.post('/users', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function deleteUser(uuid: any) {
  if (uuid) {
    try {
      const response = await api.delete('/users/'+uuid)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function updateStatusUser(uuid: any) {
  if (uuid) {
    try {
      const response = await api.post('/users/status/'+uuid)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function getListCompanies() {
  try {
    const response = await api.post('/list/companies')

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getListRoles(data: any = []) {
  try {
    const response = await api.post('/list/roles', data)

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getListProducts(data: any = []) {
  try {
    const response = await api.post('/list/products', data)

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getUrlSocialLogin() {
  try {
    const response = await api.post('/auth/social/redirect')

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function getUserAll() {
  try {
    const response = await api.post('/me/all')

    return response
  } catch (_e) {
      //@ts-ignore
      return _e.response
  }
}

export async function updateUserMe(data: any) {
  if (data) {
    try {
      const response = await api.put('/me/update', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function updateAccept(data: any) {
  if (data) {
    try {
      const response = await api.put('/me/accept', data)

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
  }
}

export async function getDashboard(data: any = []) {
    try {
      const response = await api.get('/dashboard', { params: data })

      return response
    } catch (_e) {
      //@ts-ignore
      return _e.response
    }
}

export async function getDashboardCompanies(data: any = []) {
  try {
    const response = await api.get('/dashboard/companies', { params: data })

    return response
  } catch (_e) {
    //@ts-ignore
    return _e.response
  }
}

export async function getDashboardFormats(data: any = []) {
  try {
    const response = await api.get('/dashboard/formats', { params: data })

    return response
  } catch (_e) {
    //@ts-ignore
    return _e.response
  }
}


export async function getDashboardUsers(data: any = []) {
  try {
    const response = await api.get('/dashboard/users', { params: data })

    return response
  } catch (_e) {
    //@ts-ignore
    return _e.response
  }
}

