import * as S from './stylesUpdateCompany';

import React, {
    useState,
    useEffect,
    useCallback,
    ChangeEvent
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../../components/Input'
import Textarea from '../../../components/Textarea'

function ModalCompanyUpdate(props: any) {
  const { updateCompanyImage } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [image, setImage] = useState(null)

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    setTitle(props.title)
    setDescription(props.description)

  },[])

   const changeTitle = (e: any) => {
      let val = e;

      if(val === null)
        val = '';
      else
        if(typeof e.target !== 'undefined')
          val = e.target.value;

    setTitle(val)
  }

  const changeDescription = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDescription(val)
 }

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {

        //@ts-ignore
        formData.append(key, data[key]);

    }

    if (image !== null) {
      //@ts-ignore
      formData.append("files", image);
    }

    updateCompanyImage(formData, props.uuid)
  }

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    
    if (!file) {
      setImage(null);
    }

    //@ts-ignore
    setImage(file);
    
  }, []);

  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.CompanyWrapper>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>


                    <S.CompanyForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Container fluid className="p-0">
                        <Row>

                          <Col md={12} lg={12}>

                            <Form.Group>
                              <FormLabel className="form-label">Upload Logo</FormLabel><br />
                              <Input name="image" placeholder="Imagem" type="file" onChange={handlePreview} />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <FormLabel className="form-label">Nome da Empresa</FormLabel>
                              <Input name="title" value={title} onChange={e => changeTitle(e)} placeholder="Digite o nome da empresa" type="text" />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <FormLabel className="form-label">Descrição da Empresa</FormLabel>
                              <Textarea name="description" value={description} onChange={e => changeDescription(e)}  placeholder="Digite a descrição da Empresa" />
                            </Form.Group>

                            <Form.Group className="text-end">
                              <S.Button type="submit">
                                Salvar
                              </S.Button>
                            </Form.Group>

                          </Col>


                        </Row>
                      </Container>
                    </S.CompanyForm>
                  </Col>
                </Row>
              </Container>

            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalCompanyUpdate;
