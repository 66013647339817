import { Route, BrowserRouter } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Companies from '../pages/Companies';
import Company from '../pages/Companies/show';
import CompanyCreate from '../pages/Companies/create';
import CompanyDetail from '../pages/Companies/detail';
import Users from '../pages/Users';
import User from '../pages/Users/show';
import UserCreate from '../pages/Users/create';
import UserMe from '../pages/Users/me';
import Accept from '../pages/Accept';
import AdminDashboard from '../pages/AdminDashboard';
import useReadLocalStorage from '../hooks/useReadLocalStorage';

const AppRoutes = () => {

  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');

  return (
    
    <BrowserRouter>

    {user['accept'] !== 1 &&
      <>
        <Route component={Accept} path='/' exact />
      </>
    }

    {user['accept'] === 1 &&
      <>
        <Route component={Dashboard} path='/' exact />
        <Route component={Companies} path='/companies' exact />
        <Route component={Company} path='/company/:uuid' exact />
        <Route component={CompanyCreate} path='/companies/create' exact />
        <Route component={CompanyDetail} path='/company/detail/:uuid' exact />
        <Route component={Users} path='/users' exact />
        <Route component={User} path='/user/:uuid' exact />
        <Route component={UserCreate} path='/users/create' exact />
        <Route component={UserCreate} path='/users/create/:uuid' exact />
        <Route component={UserMe} path='/me' exact />
        <Route component={AdminDashboard} path='/dashboard' exact />
      </>
    }
    


    </BrowserRouter>
  );
};

export default AppRoutes;
