import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';
import useReadLocalStorage from '../hooks/useReadLocalStorage';

const Routes = () => {
  const user = useReadLocalStorage('@login-reanimate/user');
  const token = useReadLocalStorage('@login-reanimate/token');

  return token && user ? <AppRoutes /> : <AuthRoutes />;
};

export default Routes;
