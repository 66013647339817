import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Menu = styled.ul`
    position: absolute;
    left: 35px;
    top: 35px; 
    margin: 0;
    padding: 0;
    z-index: 10;

    li {
        list-style: none;
        display: inline-block;
        margin: 0 8px;
        color: #e8336a;

        &:first-child {
            margin: 0 8px 0 0;
        }

        a {
            text-decoration: none;
            color: #e8336a;
        }

        &.btn-logout {
            cursor: pointer;
        }
    }

    .icon-profile-menu {
        position: absolute;
        right: 35px;
        top: 35px;
    }
`;

export const ImgLink = styled(Link)`
    position: absolute;
    right: 35px;
    top: 35px;
    z-index: 20;
`;

export const ManualLink = styled.a`
    position: absolute;
    right: 35px;
    top: 75px;
    z-index: 20;
    text-decoration: none;
    color: #DD1B58 !important;
    font-size: 14px;
    font-family: 'Segoe MT';
    font-weight: bold;
    font-style: normal;
`;