import axios from 'axios'

const localHost = ['127.0.0.1', 'localhost']
const isLocal = !!~localHost.indexOf(window.location.hostname)

const development = 'http://localhost:8000/api'
//const development = 'https://api.reanimate.com.br/api'
const production = 'https://api.reanimate.com.br/api'

const host =
  isLocal ? development : production

const api = axios.create({
  baseURL: host
})

//api.defaults.withCredentials = true;
//api.get('http://localhost:8000/sanctum/csrf-cookie').then((r) => {});

export const apiRedirect = (uri: any) => {
  window.location.href = host + '/' +uri;
}

export default api
