import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    background: #fcfcfc;
    *{
        margin: 0;
        padding: 0;
        overflow: unset;
    }
    *,button,input{
        font-family: 'Segoe UI', sans-serif;

        strong {
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
        }
    }

    .bg-overlay {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        z-index: 999;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &.active {
            display: block;
        }
    }

`;