import * as S from './styles';
import InputMask from 'react-input-mask';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea'

import logoReanimateColors from '../../assets/images/logo-re.animate-colors.png'
import iconArrowLeft from '../../assets/images/icon-arrow-left.png'
import logoReanimateWhite from '../../assets/images/logo-re.animate-white.png'
import imageCloudWhite1 from '../../assets/images/image-cloud-white-1.png'
import imageCloudWhite2 from '../../assets/images/image-cloud-white-2.png'

import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useAuth } from '../../context/auth';

function Contact() {

  const { sendContact } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    sendContact(data)
  }

  return (
    <S.Container>
      <Container fluid>
        <Row>
          <Col lg={3} xl={4} xxl={5}>
            <Row className="justify-content-center align-items-center">
              <Col className="text-center">
                <img src={logoReanimateColors} className="logo-reanimate-main" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600" />
              </Col>
            </Row>
          </Col>
          <Col lg={9} xl={8} xxl={7} className="formRight">
            <Link to='/'>
              <img src={iconArrowLeft} className="icon-arrow-left" />
            </Link>
            <img src={logoReanimateWhite} className="logo-reanimate-header" data-aos="fade-down" data-aos-duration="1000" />
            <img src={imageCloudWhite1} className="image-cloud-white-1 cloud_move" />
            <img src={imageCloudWhite2} className="image-cloud-white-2 cloud_move_2" />

            <S.ContactWrapper>
              <S.ContactForm ref={formRef} onSubmit={handleSubmit} className="balloon_move"  data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300">

                <h3>Agende sua Demo!</h3>
                <p className="description">Nos conte um pouco sobre você e como podemos te ajudar.</p>                

                <Form.Group>
                  <Input name="name" placeholder="Nome" type="text" />
                </Form.Group>

                <Form.Group>
                  <Input name="email" placeholder="Email" type="Email" />
                </Form.Group>

                <Form.Group>
                  <Input name="agency" placeholder="Agência/Produtora" type="text" />
                </Form.Group>

                <Form.Group>
                  <InputMask mask="(99)99999-9999" >
                    {() => (
                      <Input name="mobile" placeholder="Telefone" />
                    )}
                  </InputMask>
                </Form.Group>

                <Form.Group>
                  <Textarea name="message" placeholder="Mensagem" />
                </Form.Group>

                <Form.Group className="text-end">
                  <S.Button type="submit">
                    Enviar
                  </S.Button>
                </Form.Group>
              </S.ContactForm>
            </S.ContactWrapper>
          </Col>
        </Row>
      </Container>
    </S.Container>
  );
};

export default Contact;
