import styled from 'styled-components';
import { Form as F } from '@unform/web'

import imageBalloon from '../../assets/images/image-balloon.png'

export const Container = styled.div`
    .logo-reanimate-main {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) !important;
    }

    .formRight {
        position: relative;
        background-color: #DD1B58;


        .icon-arrow-left {
            position: absolute;
            left: 92px;
            top: 179px;

            @media (max-width: 800px) {
                left: 20px;
                top: 170px;
            }
        }

        .logo-reanimate-header {
            position: absolute;
            right: 86px;
            top: 54px;
            width: 192px;
            height: auto;

            @media (max-width: 800px) {
                right: 30px;
            }
        }

        .image-cloud-white-1 {
            position: absolute;
            left: 40%;
            top: 135px;
            width: 168px;
            height: auto;

            @media (max-width: 800px) {
                display: none;
            }
        }

        .image-cloud-white-2 {
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            width: 81px;
            height: auto;

            @media (max-width: 1850px) {
                right: 10px;
            }

            @media (max-width: 800px) {
                display: none;
            }
        }
    }

    @keyframes cloud_move {
        0% {
            left: 40%;
        }
    
        50% {
            left: calc(95% - 168px);
        }
        
        100% {
            left: 40%;
        }
    }

    .cloud_move {
        animation: cloud_move 50s linear infinite;
    }

    @keyframes cloud_move_2 {
        0% {
            right: 10px;
        }
    
        50% {
            right: 0;
        }
        
        100% {
            right: 10px;
        }
    }

    .cloud_move_2 {
        animation: cloud_move_2 4s linear infinite;
    }

    @keyframes balloon_move {
        0% {
            background-position: right calc(50%);
        }
    
        50% {
            background-position: right calc(50% - 50px);
        }
        
        100% {
            background-position: right calc(50%);
        }
    }

    .balloon_move {
        animation: balloon_move 10s infinite;
    }
`;


export const FormWrapper = styled.div`
    padding: 251px 0 150px;

    p.description {
        margin: 0 0 30px;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;

        strong {
            font-weight: 700;
        }
    }

    h3 {
        margin: 0 0 20px;
        font-size: 35px;
        font-family: 'Segoe Boot Semilight';
        font-weight: 300;
        font-style: normal;
        line-height: 1;
        color: #fff;
    }

    input,
    textarea {
        width: 100%;
        height: 51px;
        border: 2px solid #fff;
        border-radius: 40px;
        margin: 0 0 15px;
        padding: 0 20px;
        background: transparent;
        outline: none;
        font-size: 15px;
        font-weight: 400;
        color: #fff;

        ::placeholder {
            opacity: .5;
            color: #fff;
            font-weight: 400;
        }
    }

    .btn-show-password {
        margin: 0 0 20px;
        font-size: 15px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
    }

    a {
        color: #fff;
    }

`;

export const FormContainer = styled(F)`
    padding: 0 240px 0 80px;
    margin: 0 150px 20px 120px;
    min-height: 700px;
    background: url(${imageBalloon}) center right no-repeat;

    @media (max-width: 1850px) {
        padding: 0 240px 0 60px;
        margin: 0 100px 20px 100px;
    }

    @media (max-width: 1690px) {
        padding: 0 240px 0 40px;
        margin: 0 100px 20px 40px;
    }

    @media (max-width: 800px) {
        padding: 0;
        margin: 0 10px 20px 10px;
        background: none;
    }
`;

export const Button = styled.button`
    display: inline-block;
    width: 199px;
    height: 55px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E8E8E8 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: 2px solid #DD1B58;
    border-radius: 26px;
    font-size: 15px;
    font-family: 'Segoe MT';
    font-weight: bold;
    font-style: normal;
    color: #E8336A;
    transition: 0.3s;

    &:hover{
        border: 2px solid #fff;
    }
`;