import * as S from './updateUserStyles';

import React, {
    useState,
    useEffect,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../../components/Input'
import Textarea from '../../../components/Textarea'
import Switch from "react-switch";
import AsyncCreatableSelect from 'react-select/async-creatable';

import iconClose from '../../../assets/images/v2/icon-close.png'
import iconDelete from '../../../assets/images/v2/icon-delete-red.png'
import iconArrowDown from '../../../assets/images/v2/icon-arrow-down.png'

function ModalUserUpdate(props: any) {
  const { getUser, getListCompanies, getListRoles, getListProducts, updateUser, getCompanyTeams, addCompanyTeam } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [company, setCompany] = useState("")
  const [dataUser, setDataUser] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [products, setproducts] = useState([])

  const [status, setStatus] = useState("0")
  const [uuid, setUUID] = useState("")

  const [listCompanies, setlistCompanies] = useState([])
  const [listRoles, setlistRoles] = useState([])
  const [listProducts, setlistProducts] = useState([])
  const [tracker, setTracker] = useState([])


  const changeName = (e: any) => {
      let val = e;

      if(val === null)
        val = '';
      else
        if(typeof e.target !== 'undefined')
          val = e.target.value;

    setName(val)
  }

  const changeEmail = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setEmail(val)
  }

  const changeRole = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setRole(val)
  }

  const changeCompany = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setCompany(val)
  }

  const changeStatus = (target: any) => {
    let status = target ? '1' : '0';
    setStatus(status)
  }
  
  const changeProducts = (e: any) => {
    if(e.target.checked === true)   
      e.target.classList.add("product-active-update-user");
    else
      e.target.classList.remove("product-active-update-user");

      var elementos = document.getElementsByClassName('product-active-update-user');

      let products: any = [];
      for (var i = 0; i < elementos.length; i++) {
        products.push(elementos[i].getAttribute('value'));
      }

      setproducts(products)
  }

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;


    if(typeof props.userUpdate.uuid !== "undefined") {

        let data = {};

        if(props.months !== ''){
          //@ts-ignore
          data['months'] = props.months;
        }

        if(props.date_start !== ''){
          //@ts-ignore
          data['date_start'] = props.date_start;
        }
    
        if(props.date_end !== ''){
          //@ts-ignore
          data['date_end'] = props.date_end;
        }

        getUser(props.userUpdate.uuid, data).then(res => {

            console.log(res);

            //@ts-ignore
            res.user_products.forEach(element => {
              //@ts-ignore
              products.push(element.product.uuid);
            });
            setproducts(products)
            
            changeName(res.name)
            changeEmail(res.email)
            changeRole(res.user_role.uuid)
            changeCompany(res.user_companies[0].company.title)
            changeStatus(res.status)
            setUUID(res.uuid)
            setTracker(res.user_tracker)

            let newDataUser = {
                uuid: res.uuid,
                name: res.name
            }

            //@ts-ignore
            setDataUser(newDataUser);
      
            let company:any = [];
            if(user['user_role']['super'] === 1) {
              company = { company: res.user_companies[0].company.uuid }
            }  
            getListProducts(company).then(res => {
              setlistProducts(res)
            })

            if(res.user_team.length > 0) {
              let team: any = {
                value: res.user_team[0].team.uuid,
                label: res.user_team[0].team.title,
                isDisable: true
              };
        
              setSelectedOptions(team);
            }
      
            let companyRole = { company: res.user_companies[0].company.uuid }
            getListRoles(companyRole).then(res => {
              setlistRoles(res)
            })
        })
      
        if(user['user_role']['super'] === 1) {
          getListCompanies().then(res => {
            setlistCompanies(res)
          })
        }
    }

  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {    
    //@ts-ignore
    data['products'] = products;
    //@ts-ignore
    delete data.user_company;
    //@ts-ignore
    delete data.user_role;
    //@ts-ignore
    if(data.password == '') {
      //@ts-ignore
      delete data.password;
      //@ts-ignore
      delete data.password_confirmation;
    }

    //@ts-ignore
    if(typeof selectedOptions.value !== 'undefined') {
      //@ts-ignore
      data['team'] = selectedOptions.value;
    }

    updateUser(data, uuid)
  }

  const handleSelectChange = (selected: any) => {
    setSelectedOptions(selected);
  };

  const handleCreate = (inputValue: any) => {

    //@ts-ignore
    let data: any = {};
    //@ts-ignore
    data['title'] = inputValue;

    addCompanyTeam(data, props.uuid).then(res => {
      
      let data: any = {
        value: res.uuid,
        label: res.title,
        isDisable: true
      };

      setSelectedOptions(data);

    })
  };

  const promiseOptions = (inputValue: any) =>
    new Promise((resolve) => {

      //@ts-ignore
      let data: any = {};
      //@ts-ignore
      data['search'] = inputValue;

      getCompanyTeams(data, props.uuid).then(res => {
      
        let formattedOptions = [];
        formattedOptions = res.map((item: any) => (
          {
              value: item.uuid,
              label: item.title,
              isDisable: true
          }));

        resolve(formattedOptions)
      })

  });

  const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: 'white',
        border: 'solid 1px #707070 !important',
        borderRadius: '4px',
        padding: '0 20px',
        width: '100%',
        height: '48px',
        fontSize: '16px',
        fontWeight: '400',
        color: '#000',
        '&:placeholder': {
            opacity: '.5',
            color: '#000',
            fontWeight: '400',
        },
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        '&:disabled': { color: '#CED4DA', backgroundColor: 'transparent' },
        padding: 10,
        backgroundColor: state.isFocused ? '#CCCCCC' : 'white',
        color: state.isFocused ? 'white' : 'black',
        '&:hover': { backgroundColor: '#CCCCCC', color: 'white' },
        border: 'solid 1px rgba(0, 0, 0, 0.1)',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        textIndent: '-9999px',
        color: 'transparent',

        '&:after': {
            content: "''",
            backgroundImage: `url(${iconArrowDown})`,
            backgroundSize: 'contain',
            width: '13px',
            height: '8px',
            display: 'inline-block',
            position: 'absolute',
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
        },

    }),
  };

  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.UserWrapper>

              <img src={iconClose} className='icon-close' onClick={props.handleClose} />

              <h1 data-aos="fade-up" data-aos-duration="800">Visualizar usuário</h1>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={8}>


                    <S.UserForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">


                      <Container fluid className="p-0">
                        <Row>

                        <Col md={12} lg={4}>

                            <h4>Status de uso:</h4>

                            <Form.Group className="box-status">
                              <Switch
                                onChange={changeStatus}
                                checked={status == "1"}
                                className="react-switch"
                              />
                              <span>Ativo</span>
                            </Form.Group>

                            <Form.Group className="d-none">
                              <label>Status</label><br />
                              ATIVO<Input name="status" placeholder="Sim" type="radio" value={status} checked={status == "1"}  onChange={e => changeStatus(1)}  />
                              INATIVO<Input name="status" placeholder="Não" type="radio" value={status} checked={status == "0"}  onChange={e => changeStatus(0)}  />
                            </Form.Group>

                            <h4>Módulos ativos</h4>

                            {listProducts?.map((product: any) => (
                              <div key={product.product_id} className="product-item">
                                <Input name="products[]" type="checkbox" className={
                                    //@ts-ignore
                                    (products.indexOf(product.product_uuid) > -1) ? 'product-active-update-user' : ''
                                  } 
                                  checked={
                                    //@ts-ignore
                                    products.indexOf(product.product_uuid) > -1 && product.company_product_status === 1
                                  }
                                  disabled={
                                    //@ts-ignore
                                    product.company_product_status === 0
                                  }
                                  value={product.product_uuid} onChange={e => changeProducts(e)} 
                                />

                                <label
                                   id={product.product_title.toLowerCase().replace(' ', '-')}
                                  className={
                                    //@ts-ignore
                                    (product.company_product_status === 0) ? 'disabled' : ''
                                  }
                                  >{product.product_title}</label>
                              </div>
                            ))}

                            {user['user_role']['super'] === 1 &&
                            <Form.Group className='form-group'>
                              <div className='icon-delete' onClick={() => props.showModalDelete(dataUser)}>
                                <img src={iconDelete} />
                                Excluir usuário
                              </div>
                            </Form.Group>
                            }

                          </Col>


                          <Col md={12} lg={8}>

                            <Form.Group className='form-group'>
                              <FormLabel className="form-label">Nome</FormLabel><br />
                              <Input name="name" type="text" value={name} onChange={e => changeName(e)} />
                            </Form.Group>

                            <Form.Group className='form-group'>
                              <FormLabel className="form-label">Email</FormLabel><br />
                              <Input name="email" type="text" value={email} onChange={e => changeEmail(e)} />
                            </Form.Group>

                            <Form.Group className='form-group'>
                              <FormLabel className="form-label">Perfil do usuário</FormLabel><br />


                              {/** 
                              <Input name="user_role" type="text" className="disabled" value={role} disabled />
                              */}

                              <Input name="role" placeholder="Cargo" type="hidden" value={role} />
                              <select onChange={e => changeRole(e)} >
                                <option value="">Selecione</option>
                                {listRoles?.map((item: any) => (
                                  <option key={item.id} value={item.uuid}  
                                  //@ts-ignore  
                                  selected={(item.uuid === role) ? 'selected' : ''}
                                  
                                  >{item.title}</option>
                                ))}
                              </select>
                            </Form.Group>

                            <Form.Group className='form-group'>
                              <FormLabel className="form-label">Selecionar ou criar equipe</FormLabel><br />
                              <AsyncCreatableSelect
                                unstyled={true}
                                styles={customStyles}
                                defaultOptions
                                cacheOptions
                                //@ts-ignore
                                loadOptions={promiseOptions}
                                onChange={handleSelectChange}
                                onCreateOption={handleCreate}
                                value={selectedOptions}
                                placeholder='Selecione'
                                noOptionsMessage={() => ""}
                                loadingMessage={() => "Carregando"}
                              />
                            </Form.Group>

                            <Form.Group className='form-group'>
                              <FormLabel className="form-label">Criar senha</FormLabel><br />
                              <Input name="password" type="password" />
                            </Form.Group>

                            <Form.Group className='form-group'>
                              <FormLabel className="form-label">Confirmar senha</FormLabel><br />
                              <Input name="password_confirmation" type="password" />
                            </Form.Group>

                            <Form.Group className="text-end">
                              <S.Button type="submit">
                                  Salvar
                              </S.Button>
                            </Form.Group>

                          </Col>

                        </Row>
                      </Container>
                    </S.UserForm>


                  </Col>


                  <Col md={12} lg={12} xl={4}>
                    <h4>Total de peças<br /><span>Período pesquisado</span></h4>
                    
                    {tracker?.map((item: any) => {
                        
                        let totalItens: any = 0;

                        return (
                        <div className={`${item.product_title.toLowerCase().replace(' ', '-')} tracker-container`}>

                        <h3>{item.product_title}</h3>

                        {item.tracker?.map((itemTracker: any) => {

                            totalItens += itemTracker.total;

                            return (
                            <p>{itemTracker.creative} <span>{itemTracker.total}</span></p>
                        )})}

                        {item.adjustments > 0 &&
                        <p>Ajustes <span>{item.adjustments}</span></p>
                        }

                        <hr />

                        <p className="total">Total <span>{totalItens}</span></p>

                        </div>
                    )})}
                  </Col>

                </Row>
              </Container>

            </S.UserWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalUserUpdate;
