import styled from 'styled-components';
import { Form as F } from '@unform/web'

import imageBalloon from '../../assets/images/image-balloon.png'
import bgLine from '../../assets/images/bg-line.png'

export const Container = styled.div`

    .logo-reanimate-main {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) !important;
    }

    .loginRight {
        position: relative;
        background-color: #DD1B58;

        .logo-reanimate-header {
            position: absolute;
            right: 86px;
            top: 54px;
            width: 192px;
            height: auto;

            @media (max-width: 800px) {
                right: 30px;
            }
        }

        .image-cloud-white-1 {
            position: absolute;
            left: 118px;
            top: 135px;
            width: 168px;
            height: auto;

            @media (max-width: 800px) {
                left: 15px;
                top: 150px;
                width: 120px;
            }
        }

        .image-cloud-white-2 {
            position: absolute;
            right: 50px;
            top: 50%;
            transform: translateY(-50%);
            width: 81px;
            height: auto;

            @media (max-width: 1850px) {
                right: 10px;
            }

            @media (max-width: 800px) {
                display: none;
            }
        }
    }

    @keyframes cloud_move {
        0% {
            left: 5%;
        }
    
        50% {
            left: calc(95% - 168px);
        }
        
        100% {
            left: 5%;
        }
    }

    .cloud_move {
        animation: cloud_move 50s linear infinite;
    }

    @keyframes cloud_move_2 {
        0% {
            right: 10px;
        }
    
        50% {
            right: 0;
        }
        
        100% {
            right: 10px;
        }
    }

    .cloud_move_2 {
        animation: cloud_move_2 4s linear infinite;
    }

    @keyframes balloon_move {
        0% {
            background-position: right calc(50%);
        }
    
        50% {
            background-position: right calc(50% - 50px);
        }
        
        100% {
            background-position: right calc(50%);
        }
    }

    .balloon_move {
        animation: balloon_move 10s infinite;
    }
`;



export const LoginWrapper = styled.div`

    padding: 251px 0 150px;

    p.description {
        margin: 0;
        font-size: 15px;
        line-height: 1.5;
        color: #fff;

        br:nth-of-type(2) {
            @media (max-width: 1550px) {
                display: none;
            }
        }

        strong {
            font-weight: 700;
        }
    }

    h3 {
        margin: 40px 0 30px;
        font-size: 35px;
        font-family: 'Segoe Boot Semilight';
        font-weight: 300;
        font-style: normal;
        line-height: 1;
        color: #fff;
    }

    input {
        width: 100%;
        height: 51px;
        border: 2px solid #fff;
        border-radius: 40px;
        margin: 0 0 15px;
        padding: 0 20px;
        background: transparent;
        outline: none;
        font-size: 15px;
        font-weight: 400;
        color: #fff;

        ::placeholder {
            opacity: .5;
            color: #fff;
            font-weight: 400;
        }
    }

    a {
        color: #fff;
    }

    .buttonGoogle {
        display: inline-block;
        width: 199px;
        height: 51px;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 26px;
        font-size: 15px;
        font-weight: 400;
        line-height: 51px;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;

        img {
            margin-right: 4px;
        }

        &:hover{
            background: #fff;
            color: #DD1B58;
        }
    }

    .line-bar {
        margin: 30px 0;
        width: 100%;
        height: 20px;
        background: url(${bgLine}) center center no-repeat;
        text-align: center;

        span {
            display: inline-block;
            background-color: #DD1B58;
            padding: 0 10px;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            color: #F5F5F5;
        }
    }
`;


export const LoginForm = styled(F)`
    padding: 0 240px 0 80px;
    margin: 0 150px 20px 120px;
    background: url(${imageBalloon}) center right no-repeat;

    @media (max-width: 1850px) {
        padding: 0 240px 0 60px;
        margin: 0 100px 20px 100px;
    }

    @media (max-width: 1690px) {
        padding: 0 240px 0 40px;
        margin: 0 100px 20px 40px;
    }

    @media (max-width: 800px) {
        padding: 0;
        margin: 0 10px 20px 10px;
        background: none;
    }
`;


export const Button = styled.button`
    display: inline-block;
    width: 199px;
    height: 55px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #E8E8E8 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: 2px solid #DD1B58;
    border-radius: 26px;
    font-size: 15px;
    font-family: 'Segoe MT';
    font-weight: bold;
    font-style: normal;
    color: #E8336A;
    transition: 0.3s;
    
    &:hover{
        border: 2px solid #fff;
    }

`;

export const CreateAccount = styled.p`
    display: inline-block;
    width: 199px;
    height: 51px;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 26px;
    font-size: 15px;
    font-weight: 400;
    line-height: 51px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;

    &:hover{
        background: #fff;
        color: #DD1B58;
    }
`;

export const Forgotpassword = styled.p`
    margin: 0 0 20px;
    font-size: 11px;
    font-weight: 400;
    color: #fff;
    line-height: 1.5;

`;