import * as S from './formatsStyles';

import React, {
    useState,
    useEffect,
    useCallback,
    ChangeEvent
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import iconClose from '../../../assets/images/v2/icon-close.png'

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";

function ModalFormats(props: any) {
  const { getDashboardFormats } = useAuth()

  const token = useReadLocalStorage<[]>('@login-reanimate/token');

  const [formats, setformats] = useState([])

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    let data = {};

    if(props.company !== ''){
      //@ts-ignore
      data['company'] = props.company;
    }

    if(props.module !== ''){
      //@ts-ignore
      data['module'] = props.module;
    }

    if(props.date_start !== ''){
      //@ts-ignore
      data['date_start'] = props.date_start;
    }

    if(props.date_end !== ''){
      //@ts-ignore
      data['date_end'] = props.date_end;
    }

    getDashboardFormats(data).then(res => {
        setformats(res)
    })

  },[])


  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.CompanyWrapper>

              <img src={iconClose} className='icon-close' onClick={props.handleClose} />

              <h1 data-aos="fade-up" data-aos-duration="800">Formatos mais replicados</h1>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>

                    <Row>
                        <Col md={2} lg={2} xl={2}>
                            <h4>Tipo</h4>
                        </Col>
                        <Col md={8} lg={8} xl={8}>
                            <h4>Formato</h4>
                        </Col>
                    </Row>

                    {formats?.map((item: any) => {
                        return (
                            <Row className='row-itens'>
                                <Col md={2} lg={2} xl={2}>
                                    <div className={`text-label icon-label ${item.creative}`}>
                                        {item.creative}
                                    </div>
                                </Col>

                                <Col md={9} lg={9} xl={9}>
                                    <div className='text-label'>
                                        <div className='bg'></div>
                                        <div className='format-label'>{item.format}</div>
                                    </div>
                                </Col>

                                <Col md={1} lg={1} xl={1} className='text-end'>
                                    <div className='text-label'>
                                        <strong>{item.total}</strong>
                                    </div>
                                </Col>
                            </Row>
                        )})}
                  </Col>
                </Row>
              </Container>

            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalFormats;
