import * as S from './styles';

import React, {
    useState,
    useEffect,
    useCallback,
    ChangeEvent
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../../components/Input'
import Textarea from '../../../components/Textarea'
import Switch from "react-switch";

function ModalCompanyCreate(props: any) {
  const { sendCompany, getListProducts } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [unlimited, setUnlimited] = useState("0")
  const [products, setproducts] = useState([])
  const [image, setImage] = useState(null)

  const [listProducts, setlistProducts] = useState([])

  const changeUnlimited = (target: any) => {
    let unlimited = target ? '1' : '0';
    setUnlimited(unlimited)
  }

  const changeProducts = (e: any) => {
    if(e.target.checked === true)   
      e.target.classList.add("product-active");
    else
      e.target.classList.remove("product-active");

      var elementos = document.getElementsByClassName('product-active');

      let products: any = [];
      for (var i = 0; i < elementos.length; i++) {
        products.push(elementos[i].getAttribute('value'));
      }

      setproducts(products)
  }

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    getListProducts([]).then(res => {
      setlistProducts(res)
    })

  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {

      if(key !== 'products') {
        //@ts-ignore
        formData.append(key, data[key]);
      }

    }

    for (let i = 0; i < products.length; i++) {
      //@ts-ignore
      formData.append("products[]", products[i]);
    }

    if (image !== null) {
      //@ts-ignore
      formData.append("files", image);
    }

    sendCompany(formData)
  }

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    
    if (!file) {
      setImage(null);
    }

    //@ts-ignore
    setImage(file);
    
  }, []);

  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.CompanyWrapper>

              <h1 data-aos="fade-up" data-aos-duration="800">Empresas</h1>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>


                    <S.CompanyForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={5}>

                            <h4>Tipo de plano:</h4>

                            <Form.Group className="box-unlimited">
                              <Switch
                                onChange={changeUnlimited}
                                checked={unlimited == "1"}
                                className="react-switch"
                              />
                              <span>Peças ilimitadas</span>
                            </Form.Group>

                            <Form.Group className="d-none">
                              ATIVO<Input name="unlimited" placeholder="Sim" type="radio" value={unlimited} checked={unlimited == "1"}  onChange={e => changeUnlimited(1)}  />
                              INATIVO<Input name="unlimited" placeholder="Não" type="radio" value={unlimited} checked={unlimited == "0"}  onChange={e => changeUnlimited(0)}  />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <FormLabel className="form-label">Peças por mês</FormLabel>
                              <Input name="parts_per_month" placeholder="0000" type="text" />
                            </Form.Group>

                            <h4>Módulos</h4>

                            {listProducts?.map((product: any) => (
                              <div key={product.product_id} className="product-item">

                                <Input name="products[]" type="checkbox" 
                                  checked={
                                    //@ts-ignore
                                    products.indexOf(product.product_uuid) > -1
                                  } 
                                  value={product.product_uuid} onChange={e => changeProducts(e)}
                                />

                                <label id={product.product_title.toLowerCase().replace(' ', '-')}>{product.product_title}</label>
                              </div>
                              ))}

                          </Col>

                          <Col md={12} lg={7}>

                            <Form.Group>
                              <FormLabel className="form-label">Upload Logo</FormLabel><br />
                              <Input name="image" placeholder="Imagem" type="file" onChange={handlePreview} />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <FormLabel className="form-label">Nome da Empresa</FormLabel>
                              <Input name="title" placeholder="Digite o nome da empresa" type="text" />
                            </Form.Group>

                            <Form.Group className="form-group">
                              <FormLabel className="form-label">Descrição da Empresa</FormLabel>
                              <Textarea name="description" placeholder="Digite a descrição da Empresa" />
                            </Form.Group>

                            <Form.Group className="text-end">
                              <S.Button type="submit">
                                Cadastrar
                              </S.Button>
                            </Form.Group>

                          </Col>


                        </Row>
                      </Container>
                    </S.CompanyForm>
                  </Col>
                </Row>
              </Container>

            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalCompanyCreate;
