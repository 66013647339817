import styled from 'styled-components';


import iconArrowUp from '../../../assets/images/v2/icon-arrow-up.png'
import iconSettings from '../../../assets/images/v2/icon-settings.png'
import iconArrowDown from '../../../assets/images/v2/icon-arrow-down.png'

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    .bg-alpha {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, .5);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    ul {
        margin: 20px 0 20px;
        padding: 0;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 5px;
            padding: 8px 10px;
            border: 1px solid #DC1A59;
            border-radius: 8px;
            list-style: none;
            font-weight: bold;
            font-style: normal;
            color: #DC1A59;

            &.disabled {
                opacity: .4;
            }

            &.selected {
                color: #fff;
                background-color: #DC1A59;                
            }

            a {
                color: #DC1A59;
            }
        }
    }
`;

export const CompanyWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 1200px;
    height: 670px;
    background-color: #FFF;
    border-radius: 10px;

    h1 {
        margin: 0;
        font-size: 24px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    h3 {
        margin: 0 0 15px;
        font-size: 16px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    .icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }
`;

export const UserList = styled.div`

    padding: 22px 12px 20px;

    .user-list {
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        padding: 0 0 20px;

        h3 {
            margin: 30px 0 8px 20px;
            font-size: 16px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
            line-height: 1;
            color: #222222;
            cursor: pointer;
            background: url(${iconArrowUp}) no-repeat center right 20px;
        }

        .user-list-item {
            position: relative;
            display: none;
            height: 32px;
            line-height: 32px;
            padding: 0 20px 0 20px;
            margin: 2px 0;
            border-radius: 8px;

            a {
                font-size: 16px;
                font-family: 'Segoe MT';
                font-weight: normal;
                font-style: normal;
                line-height: 1;
                color: #222222;

                &.line-through {
                    text-decoration: line-through !important;
                }
            }

            .total-parts {
                font-family: 'Segoe MT';
                font-weight: bold;
                font-style: normal;
                font-size: 16px;
                color: #000000;
        
                img {
                    margin: 0 0 0 10px;
                }
        
                .actions {
                    opacity: 1;
                    text-align: center;

                    img {
                        margin: 0 4px;
                    }
                }
            }

            &:hover {
                background-color: #F4F4F4;

                .total-parts {
                    .actions {
                        opacity: 1;
                    }
                }
            }

            &.admin {
                &::after {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    content: " ";
                    width: 16px;
                    height: 16px;
                    background-image: url(${iconSettings});
                }
            }
        }

        &.active {
            .user-list-item {
                display: block;
            }

            h3 {
                background: url(${iconArrowDown}) no-repeat center right 20px;
            }
        }

        a.btn-all {
            display: inline-block;
            margin: 30px 20px 0;
            font-size: 15px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
            line-height: 15px;
            color: #000000;
            cursor: pointer;
        }
    }

    h4,
    h5 {
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    h4 {
        margin: 0 0 0 10px;
        font-size: 24px;
    }

    h5 {
        margin: 0;
        font-size: 20px;
    }

`;

