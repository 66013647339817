import * as S from './styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoReanimateColors from '../../assets/images/logo-re.animate-colors.png'

import { redirectTo } from '../../utils/redirectTo'

import api from "../../services/api";
import { useLocation } from 'react-router-dom';
import React from "react";

import { useAuth } from '../../context/auth'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Redirect = (props: any) => {
  const query = useQuery();
  const token = query.get("token") ? query.get("token") : null
  token ?? (window.location.href = '/')

  const { getUserAll } = useAuth()

  React.useEffect(() => {
    if(token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      getUserAll().then(() => {
        //props.history.push('/')

        redirectTo('/')
      })
    }
  },[])

  return (
    <S.Container>
      <Container fluid>
        <Row>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col className="text-center">
                <img src={logoReanimateColors} className="logo-reanimate-main" /><br />
                <p>Carregando</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </S.Container>
  );
};

export default Redirect;
