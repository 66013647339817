import * as S from './styles';

import React, {
    useState,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'
import iconArrowLeft from '../../assets/images/icon-arrow-left-2.png'

import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../components/Input'

function User(props: any) {
  const { getUserAll, updateUserMe } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [products, setproducts] = useState([])
  const [uuid, setUUID] = useState("")


  const changeName = (e: any) => {
      let val = e;

      if(val === null)
        val = '';
      else
        if(typeof e.target !== 'undefined')
          val = e.target.value;

    setName(val)
  }
 
  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    
    getUserAll().then(res => {

      //@ts-ignore
      res.user_products.forEach(element => {
        //@ts-ignore
        products.push(element.product.uuid);
      });
      setproducts(products)

      changeName(res.name)
      setEmail(res.email)
      setCompany(res.user_companies[0].company.title)
      setUUID(res.uuid)
    })

  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {    
    //@ts-ignore
    data['products'] = products;
    //@ts-ignore
    delete data.user_company;
    //@ts-ignore
    delete data.email;
    //@ts-ignore
    if(data.password == '') {
      //@ts-ignore
      delete data.password;
      //@ts-ignore
      delete data.password_confirmation;
    }

    updateUserMe(data)
  }

  const handleBack = () => {
    props.history.goBack()
  }

  return (
    <S.Container>

      <Menu />

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.UserWrapper>
              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <h1 data-aos="fade-up" data-aos-duration="800">Meus Dados</h1>

              <img src={iconArrowLeft} onClick={handleBack} className="icon-arrow-left" data-aos="fade-up" data-aos-duration="800" data-aos-delay="300" />

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={7}>


                    <S.UserForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">


                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={8}>

                            <Form.Group>
                              <div className="company-title">{company}</div>
                              <Input name="user_company" placeholder="Empresa" type="hidden" value={company} disabled />
                            </Form.Group>

                            <Form.Group>
                              <Input name="name" placeholder="Nome" type="text" value={name} onChange={e => changeName(e)} />
                            </Form.Group>

                            <Form.Group>
                              <Input name="email" placeholder="Email" type="text" className="disabled" value={email} disabled />
                            </Form.Group>

                            <Form.Group>
                              <Input name="password" placeholder="Senha" type="password" />
                            </Form.Group>

                            <Form.Group>
                              <Input name="password_confirmation" placeholder="Confirmar Senha" type="password" />
                            </Form.Group>

                            <Form.Group className="text-start">
                              <S.Button type="submit">
                                Enviar
                              </S.Button>
                            </Form.Group>
                          </Col>

                        </Row>
                      </Container>
                    </S.UserForm>


                  </Col>
                </Row>
              </Container>

            </S.UserWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default User;
