import styled from 'styled-components';
import { Form as F } from '@unform/web'

import iconArrowRight from '../../assets/images/icon-arrow-right.png'
import iconArrowRight2 from '../../assets/images/icon-arrow-right-2.png'
import iconPlus from '../../assets/images/icon-plus.png'
import iconMasterMotion from '../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../assets/images/icon-dynamic-ads.png'
import iconAutomailer from '../../assets/images/icon-automailer.png'
import iconMasterMotion2 from '../../assets/images/v2/icon-master-motion-2.png'
import iconReplicateAds2 from '../../assets/images/v2/icon-replicate-ads-2.png'
import iconDynamicAds2 from '../../assets/images/v2/icon-dynamic-ads-2.png'
import iconArrowDown from '../../assets/images/v2/icon-arrow-down.png'
import iconSettings from '../../assets/images/v2/icon-settings.png'
import iconArrowUp from '../../assets/images/v2/icon-arrow-up.png'

export const Container = styled.div`
    border-right: 10px #DD1B58 solid;
    min-height: 1000px;

    a {
        text-decoration: none !important;
    }
`;

export const UserWrapper = styled.div`
    position: relative;
    padding: 90px 20px 0;

    .logo-reanimate-colors {
        position: absolute;
        left: 50%;
        margin-left: -67px;
        /*transform: translateX(-50%);*/
        top: 19px;
        width: 136px;
        height: auto;

        @media (max-width: 991px) {
            left: inherit;
            margin-left: 0;
            right: 80px;
            left: inherit;
            transform: inherit;
        }
    }

    h1 {
        margin: 0;
        font-size: 40px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #DC1A59;

        span {
            margin: 0;
            font-size: 40px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
            line-height: 1;
            color: #DC1A59;
            cursor: pointer;
        }

        img {
            margin: 0 20px 0 0;
            cursor: pointer;
        }

        @media (max-width: 991px) {
            font-size: 32px;
        }
    }

    h4 {
        margin: 0 0 20px;
        font-size: 20px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #000;
    }

    .form-label.info {
        font-size: 18px;
        font-weight: 400;
        color: rgba(34, 34, 34, .6);

        b {
            color: rgba(34, 34, 34, .7);
        }
    }

    .icon-arrow-left {
        display: block;
        margin: 40px 0;
        cursor: pointer;
    }

    .btn-new-register {
        position: relative;
        margin: 0 15px 0 0;
        padding: 0 20px 0 0;
        font-size: 15px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #DC1A59;
        text-decoration: underline !important;

        &::after {
            display: block;
            position: absolute;
            right: 0;
            top: 4px;
            content: " ";
            width: 12px;
            height: 12px;
            background-image: url(${iconPlus});
        }
    }

    /**/

    .tracker-container {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 0 0 23px;
        color: #FFFFFF;
        overflow: hidden;

        h3 {
            padding: 23px 20px;
            font-size: 20px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
            text-transform: uppercase;
            line-height: 1;
        }

        p {
            padding: 0;
            margin: 16px 20px 0;
            font-size: 20px;
            font-family: 'Segoe MT';
            font-weight: normal;
            font-style: normal;
            line-height: 24px;

            span {
                display: block;
                font-size: 24px;
                font-weight: bold;
                float: right;
            }

            &.total {
                font-size: 24px;
                font-weight: bold;
            }
        }

        hr {
            margin: 16px 20px 0;
            background-color: #FFFFFF;
        }
    }

    .master-motion {
        background-color: #009bdd;

        h3 {
            background-color: #1C91CC;
            background-image: url(${iconMasterMotion2});
            background-repeat: no-repeat;
            background-position: center right 20px;
        }
    }
    
    .replicate-ads {
        background-color: #7b4394;

        h3 {
            background-color: #643D84;
            background-image: url(${iconReplicateAds2});
            background-repeat: no-repeat;
            background-position: center right 20px;
        }
    }

    .dynamic-ads {
        background-color: #ff7000;

        h3 {
            background-color: #ED6903;
            background-image: url(${iconDynamicAds2});
            background-repeat: no-repeat;
            background-position: center right 20px;
        }
    }

    .automailer {
        background-color: #f3ba14;

        h3 {
            background-color: #f3ba14;
        }
    }

    .general-total {
        width: 100%;
        height: 83px;
        padding: 0 18px;
        border: 1px solid #939393;
        border-radius: 10px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        font-size: 28px;
        line-height: 83px;

        span {
            display: block;
            font-size: 28px;
            float: right;
        }
    }

    /**/

    .form-filters {

        margin: 0 0 30px;

        .form-label {
            font-size: 16px;
            font-weight: 400;
            color: #222222;            
        }

        .search-label {
            position: relative;
            margin: 0;

            img {
                position: absolute;
                right: 20px;
                top: 14px;
                cursor: pointer;
            }
        }

        input[type="text"],
        input[type="date"],
        select {
            width: 250px;
            height: 48px;
    
            border: 1px solid #707070;
            border-radius: 4px;
    
            margin: 0 8px;
            padding: 0 20px;
            background: transparent;
            outline: none;
            font-size: 16px;
            font-weight: 400;
            color: #000;
    
            ::placeholder {
                opacity: .5;
                color: #000;
                font-weight: 400;
            }
        }

        input[type="date"] {
            width: 160px;
        }

        select {
            appearance: none;
            background: transparent;
            background-image: url(${iconArrowDown});
            background-repeat: no-repeat;
            background-position: right 20px center;
        }

        .btn-Search {
            display: inline-block;
            position: relative;
            margin: 20px 0 0;
            width: 90px;
            height: 40px;
            background: transparent linear-gradient(180deg, var(--unnamed-color-dd1b58) 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
            background: transparent linear-gradient(180deg, #DD1B58 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 9px 9px #00000029;
            border: none;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
        }
    }

    /**/

    .product-item {
        margin: 0 0 20px;

        label {
            position: relative;
            height: 50px;
            line-height: 50px;
            padding-left: 70px;
            margin-left: 10px;
            font-family: 'AmpleSoft';
            font-size: 18px;
            font-weight: bold;
            font-style: normal;

            &.disabled {
                opacity: .2;
            }

            &#master-motion {
                color: #009bdd;
            }
            &#master-motion:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconMasterMotion});
                background-size: 50px;
            }

            &#replicate-ads {
                color: #7b4394;
            }
            &#replicate-ads:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconReplicateAds});
                background-size: 50px;
            }

            &#dynamic-ads {
                color: #ff7000;
            }
            &#dynamic-ads:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconDynamicAds});
                background-size: 50px;
            }

            &#automailer {
                color: #f3ba14;
            }
            &#automailer:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconAutomailer});
                background-size: 50px;
            }
        }

        input[type="checkbox"] {
        }
    }

`;

export const UserForm = styled(F)`

    input[type="text"],
    input[type="password"],
    select,
    textarea {
        width: 100%;
        height: 51px;

        border: 1px solid #707070;
        border-radius: 15px;

        margin: 0 0 15px;
        padding: 0 20px;
        background: transparent;
        outline: none;
        font-size: 20px;
        font-weight: 400;
        color: #000;

        &.disabled {
            opacity: .3;
        }

        ::placeholder {
            opacity: .5;
            color: #000;
            font-weight: 400;
        }
    }

    textarea {
        padding: 20px 20px;
        height: 210px;
    }

    .company-title {
        margin: 0 0 30px;
        font-size: 25px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        color: #DC1A59;
    }
`;


export const UserList = styled.div`
    border: 1px solid #D5D5D5;
    border-radius: 10px;
    padding: 22px 12px 20px;
    max-height: 600px;
    overflow: auto;

    .user-list {
        border-bottom: 1px solid #D5D5D5;
        padding: 0 0 20px;

        h3 {
            margin: 30px 0 8px 20px;
            font-size: 16px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
            line-height: 1;
            color: #222222;
            cursor: pointer;
            background: url(${iconArrowUp}) no-repeat center right 20px;
        }

        .user-list-item {
            position: relative;
            display: none;
            height: 32px;
            line-height: 32px;
            padding: 0 20px 0 20px;
            margin: 2px 0;
            border-radius: 8px;

            a {
                font-size: 16px;
                font-family: 'Segoe MT';
                font-weight: normal;
                font-style: normal;
                line-height: 1;
                color: #222222;

                &.line-through {
                    text-decoration: line-through !important;
                }
            }

            .total-parts {
                font-family: 'Segoe MT';
                font-weight: bold;
                font-style: normal;
                font-size: 16px;
                color: #000000;
        
                img {
                    margin: 0 0 0 10px;
                }
        
                .actions {
                    opacity: 0;
                    text-align: center;

                    img {
                        margin: 0 4px;
                        cursor: pointer;
                    }
                }
            }

            &:hover {
                background-color: #F4F4F4;

                .total-parts {
                    .actions {
                        opacity: 1;
                    }
                }
            }

            &.admin {
                &::after {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 10px;
                    content: " ";
                    width: 16px;
                    height: 16px;
                    background-image: url(${iconSettings});
                }
            }
        }

        &.active {
            .user-list-item {
                display: block;
            }

            h3 {
                background: url(${iconArrowDown}) no-repeat center right 20px;
            }
        }
    }

    .user-list:last-child {
        border-bottom: none !important;
    }

    h4,
    h5 {
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    h4 {
        margin: 0 0 0 10px;
        font-size: 24px;
    }

    h5 {
        margin: 0;
        font-size: 20px;
    }
`;

export const Button = styled.button`
    position: relative;
    margin: 20px 0 0;
    width: 206px;
    height: 51px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-dd1b58) 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #DD1B58 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: none;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;

    &::after {
        display: block;
        position: absolute;
        right: 18px;
        top: 18px;
        content: " ";
        width: 10px;
        height: 16px;
        background-image: url(${iconArrowRight2});
    }
`;
