import styled from 'styled-components';
import iconHTML from '../../../assets/images/v2/icon-html.png'
import iconStatic from '../../../assets/images/v2/icon-static.png'
import iconVideo from '../../../assets/images/v2/icon-video.png'

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    .bg-alpha {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, .5);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`;

export const CompanyWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 1080px;
    height: 720px;
    background-color: #FFF;
    border-radius: 10px;

    .icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    h1 {
        margin: 0;
        font-size: 24px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    h4 {
        margin: 30px 0 30px;
        font-size: 16px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #000;
    }

    .text-label{
        position: relative;
        margin: 0 0 5px;
        font-size: 19px;
        font-family: 'Segoe MT';
        font-weight: normal;
        font-style: normal;
        color: #000;

        .bg {
            position: absolute;
            display: block;
            height: 100%;
            z-index: 1;
        }

        .format-label {
            position: relative;
            z-index: 2;
        }

        strong {
            font-size: 23px;
            font-weight: bold;
            font-style: normal;
        }
    }

    .row-itens:nth-child(2) {
        .bg {
            width: 100%;
            background-color: #DD1B58;
            opacity: .6;
        }
    }

    .row-itens:nth-child(3) {
        .bg {
            width: 90%;
            background-color: #DD1B58;
            opacity: .5;
        }
    }

    .row-itens:nth-child(4) {
        .bg {
            width: 80%;
            background-color: #DD1B58;
            opacity: .4;
        }
    }

    .row-itens:nth-child(5) {
        .bg {
            width: 70%;
            background-color: #DD1B58;
            opacity: .3;
        }
    }

    .row-itens:nth-child(6) {
        .bg {
            width: 60%;
            background-color: #DD1B58;
            opacity: .2;
        }
    }

    .row-itens:nth-child(7) {
        .bg {
            width: 50%;
            background-color: #DD1B58;
            opacity: .1;
        }
    }

    .row-itens:nth-child(8) {
        .bg {
            width: 40%;
            background-color: #DD1B58;
            opacity: .05;
        }
    }

    .row-itens:nth-child(9) {
        .bg {
            width: 30%;
            background-color: #DD1B58;
            opacity: .05;
        }
    }

    .row-itens:nth-child(10) {
        .bg {
            width: 20%;
            background-color: #DD1B58;
            opacity: .05;
        }
    }

    .row-itens:nth-child(11) {
        .bg {
            width: 10%;
            background-color: #DD1B58;
            opacity: .05;
        }
    }

    .icon-label {
        padding-left: 35px;

        &.html {
            background-image: url(${iconHTML});
            background-repeat: no-repeat;
            background-position: center left 0px;
        }

        &.estatico {
            background-image: url(${iconStatic});
            background-repeat: no-repeat;
            background-position: center left 0px;
        }

        &.video {
            background-image: url(${iconVideo});
            background-repeat: no-repeat;
            background-position: center left 0px;
        }
    }



`;
