import { Route, BrowserRouter } from "react-router-dom";

import Login from "../pages/Login";
import Contact from "../pages/Contact";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Callback from "../pages/Login/callback";
import Redirect from "../pages/Login/redirect";

const AuthRoutes = () => {
  return (
    <BrowserRouter>
      <Route component={Login} path="/" exact />
      <Route component={Contact} path="/contact" />
      <Route component={ForgotPassword} path="/forgot-password" />
      <Route component={ResetPassword} path="/reset-password" />
      <Route component={Callback} path="/callback" exact />
      <Route component={Redirect} path="/login/redirect" exact />
    </BrowserRouter>
  );
};

export default AuthRoutes;
