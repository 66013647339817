import styled from 'styled-components';
import { Form as F } from '@unform/web'

import iconMasterMotion from '../../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../../assets/images/icon-dynamic-ads.png'
import iconAutomailer from '../../../assets/images/icon-automailer.png'

export const Container = styled.div`

    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    .bg-alpha {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, .5);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`;

export const UserWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 494px;
    height: 234px;
    background-color: #FFF;
    border-radius: 10px;

    h1 {
        margin: 0 0 40px;
        font-size: 24px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    p {
        margin: 0 0 30px;
        font-size: 16px;
        font-family: 'Segoe MT';
        font-weight: normal;
        font-style: normal;
        line-height: 1.3;
        color: #222222;

        b {
            font-weight: bold;
        }
    }
`;

export const UserForm = styled(F)`

    .form-group {
        margin-top: 20px;
    }
`;

export const Button = styled.button`
    position: relative;
    margin: 0;
    width: 176px;
    height: 48px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-dd1b58) 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #DD1B58 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #fff;
`;

export const ButtonCancel = styled.button`
    position: relative;
    margin: 0;
    width: 176px;
    height: 48px;
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 50px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    color: #707070;
`;