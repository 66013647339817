import * as S from './styles';

import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'
import imageRocket from '../../assets/images/image-rocket.png'
import imageCloudGray1 from '../../assets/images/image-cloud-gray-1.png'
import imageCloudGray2 from '../../assets/images/image-cloud-gray-2.png'
import imageCloudGray3 from '../../assets/images/image-cloud-gray-3.png'
import iconPreview from '../../assets/images/icon-preview.png'
import iconPreviewV2 from '../../assets/images/icon-preview-v2.png'

import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import Menu from '../../components/Menu';
import { useAuth } from '../../context/auth'
import api from "../../services/api";

import { ToastContainer, toast } from 'react-toastify';

function Dashboard() {

  const { getUserProducts } = useAuth()
 
  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [products, setProducts] = React.useState([])
  const [preview_url, setPreviewUrl] = React.useState('')
  const [preview_v2_url, setPreviewV2Url] = React.useState('')
  const [allowed_access, setAllowedAccess] = React.useState(true)

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    getUserProducts().then(res => {
      setProducts(res.products);
      setPreviewUrl(res.preview_url);
      setPreviewV2Url(res.preview_v2_url);
      setAllowedAccess(res.allowed_access);
      
      if(res.allowed_access === false) {
        toast.warn('Você chegou ao limite de peças para o período do seu plano contratado.', { position: "top-center" })
      }

    })
  },[])

  return (
    <S.Container>

      <ToastContainer />

      <Menu />
      
      <Container fluid>

        {preview_v2_url !== '' && user['user_companies'][0]['company']['main'] === 1 &&
            <S.PreviewLink href={preview_v2_url} target="_blank">
              <img src={iconPreviewV2} className="icon-preview" />
            </S.PreviewLink>
        }

        {/* 
        {preview_url !== '' && user['user_companies'][0]['company']['main'] === 1 &&
            <S.PreviewV2Link href={preview_url} target="_blank">
              <img src={iconPreview} className="icon-preview" />
            </S.PreviewV2Link>
        }
        */}

        <Row>
          <Col xl={3} xxl={4} className="d-none d-xs-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
            <Row className="justify-content-center align-items-center">
              <Col className="text-center">
                <img src={logoReanimateColors} className="logo-reanimate-main" data-aos="fade-up" data-aos-duration="1000" />
                <img src={imageRocket} className="image-rocket rocket_move_3 img-fluid" />
                <img src={imageCloudGray3} className="image-cloud-gray3 cloud_move_3" />
              </Col>
            </Row>
          </Col>
          <Col lg={12} xl={9} xxl={8}>
            <S.ProductsContainer>
                <img src={imageCloudGray1} className="image-cloud-gray1 cloud_move_2" />
                <img src={imageCloudGray2} className="image-cloud-gray2 cloud_move" />

              <h3 data-aos="fade-up" data-aos-duration="800" data-aos-delay="300">
                A mudança na sua<br />
                operação começa aqui!
              </h3>
              <p className='description' data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">Escolha um módulo abaixo para iniciar sua automação agora:</p>
              
              <Container fluid className="p-0" data-aos="fade-up" data-aos-duration="800" data-aos-delay="700">
                <Row className="justify-content-start">
                  {products?.map((product: any) => (
                    <Col md={12} lg={4} xl={4} xxl={3} key={product.title}>
                      <S.ProductLink href={product.redirect_url} className='product-link'>
                        <S.ProductWrapper disabled={!product.status || !product.redirect_url} id={product.title.toLowerCase().replace(' ', '-')}>
                          <p className='product-title'>{product?.title}</p>
                        </S.ProductWrapper>
                      </S.ProductLink>

                      <p className='product-description'>
                            {product?.description}
                      </p>
                    </Col>
                  ))}
                </Row>
              </Container>
            </S.ProductsContainer>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default Dashboard;
