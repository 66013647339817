import styled from 'styled-components';
import { Form as F } from '@unform/web'

import iconMasterMotion from '../../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../../assets/images/icon-dynamic-ads.png'
import iconAutomailer from '../../../assets/images/icon-automailer.png'
import iconMasterMotion2 from '../../../assets/images/v2/icon-master-motion-2.png'
import iconReplicateAds2 from '../../../assets/images/v2/icon-replicate-ads-2.png'
import iconDynamicAds2 from '../../../assets/images/v2/icon-dynamic-ads-2.png'

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 98;

    .bg-alpha {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, .5);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`;

export const UserWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 1085px;
    height: 733px;
    background-color: #FFF;
    border-radius: 10px;

    .icon-close {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        z-index: 5;
    }

    h1 {
        margin: 0;
        font-size: 24px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    h4 {
        margin: 30px 0 18px;
        font-size: 16px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #000;

        span {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
        }
    }

    .box-status {
        margin: 0 0 30px;
        height: 28px;

        .react-switch {
            float: left;
        }

        span {
            display: inline-block;
            margin-left: 13px;
            height: 28px;
            float: left;
            font-size: 20px;
            font-family: 'Segoe MT';
            line-height: 28px;
        }
    }

    .tracker-container {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 0 0 23px;
        color: #FFFFFF;
        overflow: hidden;

        h3 {
            padding: 23px 20px;
            font-size: 16px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;
            text-transform: uppercase;
            line-height: 1;
        }

        p {
            padding: 0;
            margin: 16px 20px 0;
            font-size: 16px;
            font-family: 'Segoe MT';
            font-weight: normal;
            font-style: normal;
            line-height: 18px;

            span {
                display: block;
                font-size: 18px;
                font-weight: bold;
                float: right;
            }

            &.total {
                font-size: 18px;
                font-weight: bold;
            }
        }

        hr {
            margin: 16px 20px 0;
            background-color: #FFFFFF;
        }
    }

    .master-motion {
        background-color: #009bdd;

        h3 {
            background-color: #1C91CC;
            background-image: url(${iconMasterMotion2});
            background-repeat: no-repeat;
            background-position: center right 20px;
        }
    }
    
    .replicate-ads {
        background-color: #7b4394;

        h3 {
            background-color: #643D84;
            background-image: url(${iconReplicateAds2});
            background-repeat: no-repeat;
            background-position: center right 20px;
        }
    }

    .dynamic-ads {
        background-color: #ff7000;

        h3 {
            background-color: #ED6903;
            background-image: url(${iconDynamicAds2});
            background-repeat: no-repeat;
            background-position: center right 20px;
        }
    }

    .automailer {
        background-color: #f3ba14;

        h3 {
            background-color: #f3ba14;
        }
    }

    .icon-delete {
        display: inline-block;
        margin: 35px 0 0;
        font-size: 15px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        color: #DC1A59;
        cursor: pointer;

        img {
            margin: 0 12px 0;
        }
    }

`;

export const UserForm = styled(F)`

    input[type="text"],
    input[type="password"],
    select,
    textarea {
        width: 100%;
        height: 48px;

        border: 1px solid #707070;
        border-radius: 4px;

        margin: 0;
        padding: 0 20px;
        background: transparent;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        color: #000;

        ::placeholder {
            opacity: .5;
            color: #000;
            font-weight: 400;
        }
    }

    textarea {
        padding: 20px 20px;
        height: 100px;
        resize: none;
    }

    .form-group {
        margin-top: 20px;
    }

    .form-label {
        margin: 0;
        font-family: 'Segoe MT';
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        color: #222222;
    }

    .product-item {
        margin: 0 0 20px;

        label {
            position: relative;
            height: 50px;
            line-height: 50px;
            padding-left: 70px;
            margin-left: 10px;
            font-family: 'Segoe MT';
            font-weight: bold;
            font-style: normal;

            &.disabled {
                opacity: .3;
            }

            &#master-motion {
                color: #009bdd;
            }
            &#master-motion:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconMasterMotion});
                background-size: 50px;
            }

            &#replicate-ads {
                color: #7b4394;
            }
            &#replicate-ads:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconReplicateAds});
                background-size: 50px;
            }

            &#dynamic-ads {
                color: #ff7000;
            }
            &#dynamic-ads:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconDynamicAds});
                background-size: 50px;
            }

            &#automailer {
                color: #f3ba14;
            }
            &#automailer:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconAutomailer});
                background-size: 50px;
            }
        }

        input[type="checkbox"] {
        }
    }
`;


export const Button = styled.button`
    position: relative;
    margin: 20px 0 0;
    width: 176px;
    height: 48px;
    background: transparent linear-gradient(180deg, #DD1B58 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: #fff;

    &:hover {
        background: transparent linear-gradient(180deg, #bdbdbd 0%, #aeaeae 100%) 0% 0% no-repeat padding-box;
    }
`;
