import * as S from './styles';

import api from "../../services/api";
import { Link, useLocation } from 'react-router-dom';
import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Input from '../../components/Input';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors.png'
import logoReanimateWhite from '../../assets/images/logo-re.animate-white.png'
import imageCloudWhite1 from '../../assets/images/image-cloud-white-1.png'
import imageCloudWhite2 from '../../assets/images/image-cloud-white-2.png'
import iconGooglePlus from '../../assets/images/icon-google-plus.png'

import { SubmitHandler, FormHandles } from '@unform/core'
import { useRef } from 'react';
import { useAuth } from '../../context/auth'

interface FormData {
  email: string;
  password: string;
  token: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = (props: any) => {
  const query = useQuery();
  const token = query.get("token") ? query.get("token") : null

  const formRef = useRef<FormHandles>(null)

  const { signIn, getUrlSocialLogin, getUserAll } = useAuth()

  const handleSubmit: SubmitHandler<FormData> = data => {
    signIn(data)
  }

  const handleLoginGoogle = () => {
    getUrlSocialLogin().then(res => {
      if(res !== undefined) {
        window.location.href = res;
      }
    })
  }

  React.useEffect(() => {
    if(token !== null) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      getUserAll().then(() => {
        props.history.push('/')
      })
    }
  },[])

  return (
    <S.Container>
      <Container fluid>
        <Row>
          <Col lg={3} xl={4} xxl={5}>
            <Row className="justify-content-center align-items-center">
              <Col className="text-center">
                <img src={logoReanimateColors} className="logo-reanimate-main" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600" />
              </Col>
            </Row>
          </Col>
          <Col lg={9} xl={8} xxl={7} className="loginRight">

            <img src={logoReanimateWhite} className="logo-reanimate-header" data-aos="fade-down" data-aos-duration="1000" />
            <img src={imageCloudWhite1} className="image-cloud-white-1 cloud_move" />
            <img src={imageCloudWhite2} className="image-cloud-white-2 cloud_move_2" />

            <S.LoginWrapper>
              <S.LoginForm ref={formRef} onSubmit={handleSubmit} className="balloon_move"  data-aos="fade-down" data-aos-duration="1000" data-aos-delay="300">

                <p className="description">
                  <strong>Olá!</strong><br />
                  Você está no <strong>REANIMATE</strong>, uma plataforma de automação que está<br />
                  revolucionando a operação do mercado publicitário.
                </p>

                <h3>Login</h3>

                <Form.Group>
                  <Input name="email" placeholder="e-mail" type="email" />
                </Form.Group>

                <Form.Group>
                  <Input name="password" placeholder="senha" type="password" />
                </Form.Group>

                <Form.Group className="text-end">
                  <Link to='/forgot-password'>
                    <S.Forgotpassword>Esqueci minha senha.</S.Forgotpassword>
                  </Link>
                </Form.Group>

                <Form.Group className="text-end">
                  <S.Button
                    type="submit">Entrar
                  </S.Button>
                </Form.Group>

                <div className="line-bar">
                  <span>OU</span>
                </div>

                <Form.Group className="text-center">
                    <div  className="buttonGoogle" onClick={handleLoginGoogle}>
                      <img src={iconGooglePlus} />Entrar com o Google
                    </div>
                </Form.Group>

                <div className="line-bar"></div>

                <Form.Group className="text-center">
                  <Link to="/contact">
                    <S.CreateAccount>Não tenho uma conta</S.CreateAccount>
                  </Link>
                </Form.Group>

              </S.LoginForm>

            </S.LoginWrapper>
          </Col>

        </Row>
      </Container>
    </S.Container>
  );
};

export default Login;
