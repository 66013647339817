import styled from 'styled-components';
import { Form as F } from '@unform/web'

import iconMasterMotion from '../../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../../assets/images/icon-dynamic-ads.png'
import iconAutomailer from '../../../assets/images/icon-automailer.png'
import iconUpload from '../../../assets/images/v2/icon-upload.png'

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    .bg-alpha {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, .5);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`;

export const CompanyWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 1080px;
    height: 680px;
    background-color: #FFF;
    border-radius: 10px;

    .icon-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    h1 {
        margin: 0;
        font-size: 24px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;

        span {
            font-weight: normal;
            font-style: normal;
        }
    }

    .companies-graph {
        position: relative;
        max-width: 50%;
        margin: 50px auto 0;
    }
}
`;
