import * as S from './styles';

import React, {
    useState,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import logoReanimateColors from '../../assets/images/v2/logo-re.animate-colors.png'
import iconMasterMotionV2 from '../../assets/images/v2/icon-master-motion-v2.png'
import iconReplicateAdsV2 from '../../assets/images/v2/icon-replicate-ads-v2.png'
import iconMaskRed from '../../assets/images/v2/icon-mask-red.png'
import iconArrowLeft from '../../assets/images/v2/icon-arrow-left.png'

import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";

import ModalCompanies from './Modal/companies';
import ModalFormats from './Modal/formats';
import ModalUsers from './Modal/users';

function AdminDashboard(props: any) {
  const { getDashboard, getListCompanies } = useAuth()

  ChartJS.register(ArcElement, Tooltip, Legend);

  const token = useReadLocalStorage<[]>('@login-reanimate/token');


  const dataTest = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  };

  const [users, setUsers] = React.useState([])
  const [tracker, setTracker] = useState([])
  const [total_parts, setTotalParts] = useState("0")
  const [creatives, setCreatives] = useState([])
  const [creatives_adjustments, setCreativesAdjustments] = useState("0")
  const [creatives_total, setCreativesTotal] = useState("0")
  const [formats, setformats] = useState([])
  const [data_companies, setDataCompanies] = useState(dataTest)
  const [companies, setCompanies] = useState([])
  const [company, setCompany] = React.useState("")
  const [date_start, setDateStart] = useState("")
  const [date_end, setDateEnd] = useState("")

  const [module, setModule] = React.useState("")

  const [show_companies, setShowCompanies] = React.useState(false)
  const [show_formats, setShowFormats] = React.useState(false)
  const [show_users, setShowUsers] = React.useState(false)
  
  const changeCompany = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setCompany(val)

    let data = {};
    if(val !== ''){
      //@ts-ignore
      data['company'] = val;
    }

    if(module !== ''){
      //@ts-ignore
      data['module'] = module;
    }

    if(date_start !== ''){
      //@ts-ignore
      data['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      data['date_end'] = date_end;
    }

    getDashboard(data).then(res => {
      setTracker(res.tracker)
      setTotalParts(res.total_parts)
      setUsers(res.users)
      setCreatives(res.creatives.data)
      setCreativesAdjustments(res.creatives.adjustments)
      setCreativesTotal(res.creatives.total)
      setformats(res.formats)
      addCompaniesChart(res.total_by_companies)
    })
  }

  const changeModule = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setModule(val)

    let data = {};
    if(val !== ''){
      //@ts-ignore
      data['module'] = val;
    }

    if(company !== ''){
      //@ts-ignore
      data['company'] = company;
    }

    if(date_start !== ''){
      //@ts-ignore
      data['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      data['date_end'] = date_end;
    }

    getDashboard(data).then(res => {
      setTracker(res.tracker)
      setTotalParts(res.total_parts)
      setUsers(res.users)
      setCreatives(res.creatives.data)
      setCreativesAdjustments(res.creatives.adjustments)
      setCreativesTotal(res.creatives.total)
      setformats(res.formats)
      addCompaniesChart(res.total_by_companies)
    })
  }

  const handleSearch = () => {

    let data = {};
    if(company !== ''){
      //@ts-ignore
      data['company'] = company;
    }

    if(module !== ''){
      //@ts-ignore
      data['module'] = module;
    }

    if(date_start !== ''){
      //@ts-ignore
      data['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      data['date_end'] = date_end;
    }

    getDashboard(data).then(res => {
      setTracker(res.tracker)
      setTotalParts(res.total_parts)
      setUsers(res.users)
      setCreatives(res.creatives.data)
      setCreativesAdjustments(res.creatives.adjustments)
      setCreativesTotal(res.creatives.total)
      setformats(res.formats)
      addCompaniesChart(res.total_by_companies)
    })

  }

  const changeDateStart = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDateStart(val)
  }

  const changeDateEnd = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDateEnd(val)
  }

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    getDashboard({}).then(res => {
      setTracker(res.tracker)
      setTotalParts(res.total_parts)
      setUsers(res.users)
      setCreatives(res.creatives.data)
      setCreativesAdjustments(res.creatives.adjustments)
      setCreativesTotal(res.creatives.total)
      setformats(res.formats)
      addCompaniesChart(res.total_by_companies)
    })

    getListCompanies().then(res => {
      setCompanies(res)
    })

  },[])


  //console.log(companies);

  const addCompaniesChart = (data: any) => {
    
    const labelsChart: any = [];
    const dataChart: any = [];
    const backgroundColor = ['rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)'];
    const borderColor = ['rgba(107, 190, 123, 1)', 'rgba(154, 208, 165, 1)', 'rgba(192, 232, 200, 1)', 'rgba(192, 232, 200, 1)'];

    data?.map((item: any) => {
      labelsChart.push(item.company_title)
      dataChart.push(item.total)
    })

    const chart = {
      labels: labelsChart,
      datasets: [
        {
          label: '# total de peças',
          data: dataChart,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
        },
      ],
    };

    //@ts-ignore
    setDataCompanies(chart);
  }


  const handleBack = () => {
    props.history.goBack()
  }

  const showModalCompanies = () => {
    setShowCompanies(true);
  };

  const hideModalCompanies = () => {
    setShowCompanies(false);
  };

  const showModalFormats = () => {
    setShowFormats(true);
  };

  const hideModalFormats = () => {
    setShowFormats(false);
  };

  const showModalUsers = () => {
    setShowUsers(true);
  };

  const hideModalUsers = () => {
    setShowUsers(false);
  };
  
  return (
    <S.Container>

      <Menu />

      {show_companies === true &&
        <ModalCompanies handleClose={hideModalCompanies} company={company} module={module} date_start={date_start} date_end={date_end} />
      }

      {show_formats === true &&
        <ModalFormats handleClose={hideModalFormats} company={company} module={module} date_start={date_start} date_end={date_end} />
      }

      {show_users === true &&
        <ModalUsers handleClose={hideModalUsers} company={company} module={module} date_start={date_start} date_end={date_end} />
      }

      <Container fluid>

        <Row>

          <Col md={12}>

            <S.CompanyWrapper>

              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <Container fluid className="p-0">
                <Row>

                  <Col md={12} lg={12} xl={12}>

                    <S.CompanyForm data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Container fluid className="p-0">
                        <Row>
                          <Col md={12} lg={3}>

                            <h1 data-aos="fade-up" data-aos-duration="800">
                              <Link to='/companies' >
                                <img src={iconArrowLeft} />
                              </Link>
                              <span>Re.animate</span>
                            </h1>

                            <Form.Group className='form-filters'>
                              <FormLabel className="form-label">Filtrar por</FormLabel>
                              <br />

                              <input name="module" type="hidden" value={company} />
                              <select onChange={e => changeCompany(e)}>
                                <option value="">Todas as empresas</option>
                                  {companies?.map((item: any) => {
                                    return (
                                      <option key={item.id} value={item.uuid}>{item.title}</option>
                                  )})}
                              </select>
                              <br />
                              <input name="module" type="hidden" value={module} />
                              <select onChange={e => changeModule(e)}>
                                <option value="">Todos os módulos</option>
                                <option key={1} value='1'>Master Motion</option>
                                <option key={1} value='2'>Replicate Ads</option>
                              </select>
                              <br />
                              <input name="date_start" type="date" value={date_start} onChange={e => changeDateStart(e)} />
                              <br />
                              <input name="date_end" type="date" value={date_end} onChange={e => changeDateEnd(e)} />
                              <br />
                              <div className='btn-Search' onClick={handleSearch}>Pesquisa</div>
                            </Form.Group>

                          </Col>

                          <Col md={12} lg={9}>
                            <Row>

                              <Col md={12} lg={4}>
                              
                              {tracker?.map((item: any) => {
                                  
                                  let totalItens: any = 0;
        
                                  return (
                                  <div className={`${item.product_title.toLowerCase().replace(' ', '-')} tracker-container`}>
    
                                    <h3>{item.product_title}</h3>
    
                                    {item.tracker?.map((itemTracker: any) => {
    
                                      totalItens += itemTracker.total;
    
                                      return (
                                      <p>{itemTracker.creative} <span>{itemTracker.total}</span></p>
                                    )})}
    
                                    {item.adjustments > 0 &&
                                    <p>Ajustes <span>{item.adjustments}</span></p>
                                    }
    
                                    <hr />
    
                                    <p className="total">Total <span>{totalItens}</span></p>
    
                                  </div>
                                )})}

                                <Form.Group>
                                  <FormLabel className="general-total">Total geral <span>{total_parts}</span></FormLabel>
                                </Form.Group>
    
                              </Col>

                              <Col md={12} lg={8}>

                                <Row>
                                  <Col md={12}>

                                    <div className='creatives-list tracker-container'>
                                      <h3>
                                        Tipo de peça mais replicada
                                      </h3>

                                      {creatives?.map((item: any) => {
                                        return (
                                            <p>{item.creative} <span>{item.total}</span></p>                  
                                      )})}
                                        
                                      <p>Ajustes <span>{creatives_adjustments}</span></p>

                                      <hr />

                                      <p className="total">Total <span>{creatives_total}</span></p>

                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={6}>
                                    <div className='formats-list tracker-container'>
                                      <h3>
                                        Formatos mais replicados
                                      </h3>

                                      {formats?.map((item: any) => {
                                        return (
                                            <p className={item.creative}>
                                              <div className='bg'></div>
                                              <strong>{item.format} ({item.creative})</strong>
                                              <span>{item.total}</span>
                                            </p>
                                      )})}
                                        
                                      <a onClick={showModalFormats}>Ver tudo</a>
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className='companies-list tracker-container'>
                                      <h3>
                                        Ranking de Agências <span>(Total de peças)</span>
                                      </h3>

                                        <div className={`companies-graph`}>
                                          <Doughnut data={data_companies} />
                                        </div>

                                        <a onClick={showModalCompanies}>Ver tudo</a>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md={12}>

                                    <S.UserList>

                                      <Container fluid className="p-0">
                                        <Row>
                                          <Col md={6} lg={6} xl={6}>
                                            <h4>Usuários que mais criaram</h4>
                                          </Col>
                                          <Col md={6} lg={6} xl={6}>
                                            <h5>Peças</h5>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col md={12} lg={12} xl={12}>

                                          <div className='user-list active'>
                                              <h3>
                                                Todas as agências
                                              </h3>
                                            {users?.map((user: any) => {
                                              return (
                                              <div className={`user-list-item ${ user?.admin === 1 ? 'admin' : '' } `}>
                                                <Row>
                                                  <Col md={6} lg={6} xl={6}>
                                                    <Link to='#' key={user.id} 
                                                    className={user?.status === 0 ? 'line-through' : '' }
                                                    >
                                                      {user?.name} ({user?.company_title})
                                                    </Link>
                                                  </Col>
                                                  <Col md={6} lg={6} xl={6}>
                                                    <Row className='total-parts'>
                                                      
                                                      {module == '' &&
                                                      <Col md={3} lg={3} xl={3}>
                                                        {user?.total_master_motion}
                                                        <img src={iconMasterMotionV2} />
                                                      </Col>
                                                      }

                                                      {module == '' &&
                                                      <Col md={3} lg={3} xl={3}>
                                                        {user?.total_replicate_ads}
                                                        <img src={iconReplicateAdsV2} />
                                                      </Col>
                                                      }

                                                      {module == '1' &&
                                                      <Col md={3} lg={3} xl={3}>
                                                        {user?.total_master_motion}
                                                        <img src={iconMasterMotionV2} />
                                                      </Col>
                                                      }

                                                      {module == '2' &&
                                                      <Col md={3} lg={3} xl={3}>
                                                        {user?.total_replicate_ads}
                                                        <img src={iconReplicateAdsV2} />
                                                      </Col>
                                                      }

                                                      <Col md={6} lg={6} xl={6}>
                                                        <div className='actions'>
                                                          {user?.status !== 1 &&
                                                            <img src={iconMaskRed} />
                                                          }
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                </Row>
                                              </div>
                                            )})}

                                            <a className='btn-all' onClick={showModalUsers}>Ver tudo</a>
                                          </div>
                                          </Col>
                                        </Row>

                                      </Container>

                                    </S.UserList>

                                  </Col>
                                </Row>


                              </Col>

                            </Row>
                          </Col>


                        </Row>
                      </Container>

                    </S.CompanyForm>



                  </Col>
                </Row>
              </Container>
            </S.CompanyWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default AdminDashboard;
