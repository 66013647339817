import * as S from './deleteStyles';

import React, {
    useState,
    useEffect,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import useReadLocalStorage from '../../../hooks/useReadLocalStorage';
import { useAuth } from '../../../context/auth'
import api from "../../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

function ModalUserDelete(props: any) {
  const { deleteUser } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');

  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {
    deleteUser(props.userDelete.uuid)
  }

  return (
    <S.Container>

      <div className="bg-alpha" onClick={props.handleClose}></div>

      <Container fluid>

        <Row>
          <Col md={12}>
            <S.UserWrapper>

              <h1 data-aos="fade-up" data-aos-duration="800">Excluir usuário</h1>

              <p>Você tem certeza que deseja excluir o usuário <b>{props.userDelete?.name}</b></p>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={12}>

                    <S.UserForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">

                      <Container fluid className="p-0">
                        
                        <Row>
                          <Col md={12} lg={12}>


                            <Form.Group className="text-end">
                              <S.ButtonCancel type="button" onClick={props.handleClose}>
                                  Cancelar
                              </S.ButtonCancel>

                              <S.Button type="submit">
                                  Excluir
                              </S.Button>
                            </Form.Group>

                          </Col>

                        </Row>
                      </Container>

                    </S.UserForm>

                  </Col>
                </Row>
              </Container>


            </S.UserWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default ModalUserDelete;
