import * as S from './styles';

import React, {
    useState,
  } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'

import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";
import { useRef } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

import Input from '../../components/Input'

function User(props: any) {
  const { updateAccept } = useAuth()
  const formRef = useRef<FormHandles>(null)

  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [accept, setAccept] = useState("0")

  const changeAccept = (e: any) => {
    if(e.target.checked === true)   
      setAccept('1')
    else
      setAccept('0')
  }

 
  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  },[])

  const handleSubmit: SubmitHandler<FormData> = (data) => {    

    //@ts-ignore
    data['accept'] = accept;

    updateAccept(data);
  }

  return (
    <S.Container>

      <Menu />

      <Container fluid>
        <Row>
          <Col md={12}>
            <S.UserWrapper>
              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <h1 data-aos="fade-up" data-aos-duration="800">Termos de uso</h1>

              <Container fluid className="p-0">
                <Row>
                  <Col md={12} lg={12} xl={7}>


                    <S.UserForm ref={formRef} onSubmit={handleSubmit} data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">


                      <Container fluid className="p-0">

                        <Row>
                          <Col md={10} className="mb-4 mt-4">
                            <S.Regulation>
                              <h4>
                              Re.Animate Termos de Uso
                              </h4>

                              <p>
                              Leia atentamente estes <b>termos de acesso para o uso licenciado</b> da ferramenta <b>Re.Animate</b> antes de
                              aceitar as condições de uso. Ao tocar o botão aceitar/assinar, o usuário afirma ter lido e entendido estes
                              <b>Termos de uso</b>. A ferramenta Re.Animate consiste em uma ferramenta inovadora de automação de
                              tecnologia para criação e replicação de banners, vídeos e peças digitais estáticas ou animadas em vários
                              formatos desejados, incluindo HTML utilizadas em campanhas publicitárias e de marketing digital com
                              base em um template pré-projetado a partir de uma peça master de guia PSD “Photoshop”. Ao utilizar
                              a ferramenta, o usuário concorda que o autor/detentor <b>Martech Media</b> e a <b>Point Media</b> não aceitarão
                              para si a responsabilidade pelo uso correto ou incorreto da ferramenta e muito menos pelas
                              informações contidas, criadas e emitidas pelo(s) usuário(s) em suas peças, incluindo, mas não se
                              limitando os dados sobre as campanhas, ofertas de produtos ou de serviços ou pelo uso da <b>IA</b> para
                              compor a locução dos textos e das imagens de propagandas ou de ofertas de produtos ou de serviços
                              criados pelo(s) usuário(s). Em caso de violação aos direitos, incluindo os de propriedade, a <b>Martech
                              Media</b> e a <b>Point Media</b> podem impor contra o(s) usuário(s) todas as medidas judiciais e extrajudiciais
                              cabíveis para a preservação dos direitos patrimoniais, morais, da propriedade intelectual, autorais e de
                              segredos industriais. Estando em conformidade com estes <b>Termos de uso</b> o(s) usuário(s), terão acesso
                              restrito e de acordo com os termos licenciados de forma não exclusiva, não transferível e não
                              sublicenciável do Re.Animate. É vedado o uso não autorizado, cópia, adaptação, alteração, execução
                              para demonstrações públicas e para fins particulares de venda, negociação ou de transferência ilegal da
                              tecnologia da ferramenta à terceiros de forma direta ou indireta, somente a <b>Martech Media</b> e a <b>Point
                              Media</b> podem licenciar e comercializar o <b>Re.Animate</b>. Esta ferramenta foi elaborada, registrada e
                              publicada no INPI com todos os direitos de marcas e patentes reservados à <b>MARTECH MEDIA
                              SOLUTIONS LTDA</b>. Ao utilizar esta ferramenta, você deve, obrigatoriamente, concordar com estes
                              termos. Estes termos regem o seu uso por licença e criam um acordo jurídico vinculativo para proteção
                              do detentor e de seus patrocinadores, que podem impor contra você em caso de violação todas as
                              medidas judiciais e extrajudiciais cabíveis e correspondentes a propriedade intelectual, de direitos
                              autorais e de segredo industrial. Se você não concordar com estes termos de uso, não utilize esta
                              ferramenta.
                              </p>

                              <h4>
                              Registro de contas
                              </h4>

                              <p>
                              O objetivo das informações requeridas para o registro da(s) conta(s) de acesso(s) e uso por
                              licenciamento da ferramenta <b>Re.Animate</b> serve para garantir que o acesso será realizado por usuário(s)
                              vinculado(s) ou pelo próprio Licenciado. Estas informações (e-mail de login, senha e dados cadastrais)
                              não serão utilizados, publicados, cedidos ou transferidas à terceiros. Ao registrar as informações para o
                              uso da ferramenta <b>Re.Animate</b>, o(s) usuário(s) concordam que suas informações de registro são
                              verdadeiras e precisas e deverão ser mantidas atualizadas para que continuem sendo verdadeiras,
                              precisas, atuais e completas. O Licenciado e o(s) usuário(s) devem estar ciente(s) que são responsáveis
                              pelos dados fornecidos para o seu cadastro que devem ser mantidos sob sigilo juntamente com as suas
                              senhas e logins de acessos, essas informações não devem ser repassadas ou transferidas à terceiros não
                              licenciados sob pena de ter o seu acesso suspenso ou cancelado por facilitação de “acesso ou uso
                              indevido”. O Licenciado é o responsável por toda a atividade que ocorre no uso da ferramenta através
                              do(s) seu(s) usuário(s) cadastrado(s) e dispositivos de acessos. A <b>Martech Media</b> e a <b>Point Media</b> não se
                              responsabilizam por qualquer acesso não autorizado ou pelo uso de informações da sua conta por
                              acessos indevidos.
                              </p>

                              <h4>
                              Acesso a conteúdo restrito
                              </h4>

                              <p>
                              O acesso a ferramenta <b>Re.Animate</b> não possui área de conteúdo aberto (livre acesso), sendo o seu uso
                              totalmente restrito ao Licenciado e sua equipe cadastrada. Caso haja alguma inconsistência de cadastro,
                              medidas de proteção e de segurança serão adotadas pela <b>Martech Media</b> e pela <b>Point Media</b> e,
                              poderão impactar no bloqueio imediato do(s) acesso(s) até que sejam esclarecidas a(s) ocorrência(s).
                              </p>

                              <h4>
                              Utilização dos serviços
                              </h4>

                              <p>
                              Os serviços se destinam ao uso exclusivo por profissionais habilitados por prazo determinado e durante
                              o licenciamento. O(s) usuário(s) concordam que serão os únicos responsáveis pela conformidade com
                              todas as leis e padrões profissionais que lhes sejam aplicáveis no exercício de suas profissões. Não é
                              permitido o uso não autorizado, cópia, adaptação, alteração, execução para fins públicos ou para fins
                              particulares de venda, demonstração ou transferência à terceiros sem o consentimento expresso dos
                              proprietários licenciantes e do seu departamento jurídico.
                              </p>

                              <h4>
                              Direitos de propriedade
                              </h4>

                              <p>
                              A ferramenta <b>Re.Animate</b> é de propriedade das empresas <b>MARTECH MEDIA SOLUTIONS LTDA</b> e
                              POINT MEDIA DIGITAL LTDA. O Licenciado reconhece e concorda que a ferramenta <b>Re.Animate</b>
                              contém informações e tecnologias protegidas por leis de propriedade intelectual e outras leis aplicáveis
                              às marcas e patentes e de segredos industriais. O Licenciado concorda em não modificar, alugar,
                              arrendar, emprestar, vender, distribuir, transmitir, executar publicamente ou criar trabalhos derivados
                              ou baseados com cópia da tecnologia para fins comerciais particulares ou para quaisquer outras
                              finalidades que não se enquadrem ao uso através do licenciamento.
                              </p>

                              <h4>
                              Foro de eleição
                              </h4>

                              <p>
                              Fica eleito o Foro da Comarca Capital de São Paulo, com expressa renúncia a qualquer outro, por mais
                              privilegiado que seja, para que, perante o mesmo e pela ação competente, sejam resolvidas as questões
                              oriundas do presente termo de licença provisório de uso.
                              </p>


                              <h4>
                              Rescisão e modificação
                              </h4>

                              <p>
                              O Licenciado e seu(s) usuário(s) concordam que, sob quaisquer circunstâncias e sem prévio aviso, o
                              acesso a ferramenta <b>Re.Animate</b> e suas funcionalidades podem ser parcialmente, totalmente,
                              temporariamente ou permanentemente interrompidos ou modificados para fins de atualização que
                              poderão ser aplicadas de acordo com o interesse da <b>Martech Media</b> e da <b>Point Media</b>. Licenciado(s) e
                              usuário(s) podem ser removidos ou suspensos, caso seja verificada má utilização com ou sem aviso
                              prévio, por quaisquer motivos que interessem os detentores/autores ou seus patrocinadores.
                              </p>

                              <h4>
                              Responsabilidades
                              </h4>

                              <p>
                              Em nenhuma hipótese serão as empresas <b>MARTECH MEDIA SOLUTIONS LTDA</b> e <b>POINT MEDIA
                              DIGITAL LTDA</b> responsáveis por quaisquer danos causados à terceiros (incluindo, sem limitação,
                              danos incidentais e consequentes, lucros cessantes ou danos resultantes de perda de dados ou de
                              interrupção de negócios) decorrentes pelo mau uso ou por danos causados pela incapacidade de acessar
                              ou usar a ferramenta, sejam eles baseados em garantia, contrato, ato ilícito ou qualquer outra teoria
                              legal. As limitações de danos acima definidas são elementos fundamentais da base do presente acordo
                              entre as empresas <b>MARTECH MEDIA SOLUTIONS LTDA</b>, <b>POINT MEDIA DIGITAL LTDA</b> e o
                              Licenciado e os seu(s) usuário(s) cadastrados.
                              </p>

                              <h4>
                              UP Grades
                              </h4>

                              <p>
                              As empresas <b>MARTECH MEDIA SOLUTIONS LTDA</b> e <b>POINT MEDIA DIGITAL LTDA</b> reservam
                              o direito a seu critério, de modificar, suspender ou encerrar qualquer uma das funcionalidades dos da
                              ferramenta <b>Re.Animate</b> ou destes termos de uso a qualquer momento e sem aviso prévio. Ao continuar
                              a acessar ou utilizar os serviços após eventuais atualizações ou modificações destes termos ou das
                              funcionalidades da ferramenta, o Licenciado e seu(s) usuário(s) concordam em ficar vinculados pelos
                              termos modificados durante o período de continuidade de uso. O Licenciado e seu(s) usuário(s) devem
                              interromper imediatamente seus acessos e a utilização da ferramenta <b>Re.Animate</b> caso não concordem
                              com os termos modificados.
                              </p>

                              <h4>
                              Plenitude de acordo
                              </h4>

                              <p>
                              Estes termos constituem o acordo inteiro entre as empresas <b>MARTECH MEDIA SOLUTIONS LTDA</b>
                              e <b>POINT MEDIA DIGITAL LTDA</b> e seu(s) usuário(s) e licenciado(s) declararam que compreendem
                              inteira e exclusivamente que estes termos que substituem todos e quaisquer entendimentos orais ou
                              escritos anteriores ou acordados entre si. O Licenciado e seu(s) usuário(s) se comprometem a não
                              efetuar qualquer revelação, reprodução, utilização indevida ou não autorizada e muito menos dar
                              conhecimento indevido à terceiros de quaisquer “informações confidenciais” e de “Segredo Industrial”
                              sobre o software/ferramenta <b>Re.Animate</b>, exceto se com prévio e expresso consentimento por escrito,
                              sob pena de incorrer nas penalidades cíveis e criminais cabíveis e previstas na legislação e sem o prejuízo
                              de indenizações suplementares, na forma do artigo 416 do Código Civil de acordo com a extensão do
                              dano.
                              </p>

                              <h4>
                              Contatos
                              </h4>

                              <p>
                              Quaisquer dúvidas sobre os serviços, funcionalidades da ferramenta <b>Re.Animate</b> ou destes termos de
                              uso e feedback, por favor contacte: <a href={"mailto:contato@pointmedia.com.br"} target="_blank">contato@pointmedia.com.br</a> . Última atualização: 01 de julho de
                              2024. ©2023 <b>MARTECH MEDIA</b> e <b>POINT MEDIA</b> Todos os direitos reservados.
                              </p>
                            </S.Regulation>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12} lg={8}>

                            <Form.Group>

                              <Input name="accept" type="checkbox" 

                                  onChange={e => changeAccept(e)}
                                  value={1}
                                />

                                <label> Aceito os termos de uso da ferramenta Re. Animate</label>

                            </Form.Group>

                            <Form.Group className="text-start">
                              <S.Button type="submit" id="btn-send"
                                disabled={
                                  //@ts-ignore
                                  accept === '0'
                                }
                              >
                                Salvar
                              </S.Button>
                            </Form.Group>
                          </Col>

                        </Row>
                      </Container>
                    </S.UserForm>


                  </Col>
                </Row>
              </Container>

            </S.UserWrapper>
          </Col>
        </Row>
      </Container>

    </S.Container>
  );
};

export default User;
