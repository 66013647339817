import styled from 'styled-components';
import { Form as F } from '@unform/web'

import iconMasterMotion from '../../../assets/images/icon-master-motion.png'
import iconReplicateAds from '../../../assets/images/icon-replicate-ads.png'
import iconDynamicAds from '../../../assets/images/icon-dynamic-ads.png'
import iconAutomailer from '../../../assets/images/icon-automailer.png'
import iconUpload from '../../../assets/images/v2/icon-upload.png'

export const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99;

    .bg-alpha {
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0, .5);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`;

export const CompanyWrapper = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 812px;
    height: 593px;
    background-color: #FFF;
    border-radius: 10px;

    h1 {
        margin: 0;
        font-size: 24px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #222222;
    }

    h4 {
        margin: 30px 0 18px;
        font-size: 20px;
        font-family: 'Segoe MT';
        font-weight: bold;
        font-style: normal;
        line-height: 1;
        color: #000;
    }

    .box-unlimited {
        margin: 0 0 30px;
        height: 28px;

        .react-switch {
            float: left;
        }

        span {
            display: inline-block;
            margin-left: 13px;
            height: 28px;
            float: left;
            font-size: 20px;
            font-family: 'Segoe MT';
            font-weight: normal;
            font-style: normal;
            line-height: 28px;
        }
    }
`;

export const CompanyForm = styled(F)`

    input[type="text"],
    textarea {
        width: 100%;
        height: 48px;

        border: 1px solid #707070;
        border-radius: 4px;

        margin: 0;
        padding: 0 20px;
        background: transparent;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        color: #000;

        ::placeholder {
            opacity: .5;
            color: #000;
            font-weight: 400;
        }
    }

    textarea {
        padding: 20px 20px;
        height: 100px;
        resize: none;
    }

    #image {
        width: 100%;
        height: 130px;
        display: block;
        border: 1px dashed #707070;
        text-indent: -1000px;
        background-color: #FFFFFF;
        background-image: url(${iconUpload});
        background-repeat: no-repeat;
        background-position: center center;
        overflow: hidden;
        cursor: pointer;
    }

    .form-group {
        margin-top: 25px;
    }

    .form-label {
        margin: 0;
        font-family: 'Segoe MT';
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        color: #222222;
    }

    .product-item {
        margin: 0 0 14px;

        label {
            position: relative;
            height: 50px;
            line-height: 50px;
            padding-left: 70px;
            margin-left: 10px;
            font-size: 18px;
            font-family: 'AmpleSoft';
            font-weight: bold;
            font-style: normal;

            &#master-motion {
                color: #009bdd;
            }
            &#master-motion:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconMasterMotion});
                background-size: 50px;
            }

            &#replicate-ads {
                color: #7b4394;
            }
            &#replicate-ads:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconReplicateAds});
                background-size: 50px;
            }

            &#dynamic-ads {
                color: #ff7000;
            }
            &#dynamic-ads:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconDynamicAds});
                background-size: 50px;
            }

            &#automailer {
                color: #f3ba14;
            }
            &#automailer:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: " ";
                width: 50px;
                height: 50px;
                background-image: url(${iconAutomailer});
                background-size: 50px;
            }
        }

        input[type="checkbox"] {
        }
    }

    .master-motion {
        color: #009bdd;
        font-weight: 700;
    }

    .replicate-ads {
        color: #7b4394;
        font-weight: 700;
    }

    .dynamic-ads {
        color: #ff7000;
        font-weight: 700;
    }

    .automailer {
        color: #f3ba14;
        font-weight: 700;
    }
`;

export const Button = styled.button`
    position: relative;
    margin: 20px 0 0;
    width: 206px;
    height: 51px;
    background: transparent linear-gradient(180deg, var(--unnamed-color-dd1b58) 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #DD1B58 0%, #8F0732 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 9px #00000029;
    border: none;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
`;
