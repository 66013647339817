import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";
import * as auth from "../services/auth";
import api from "../services/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLocalStorage from "../hooks/useLocalStorage";
import { redirectTo } from '../utils/redirectTo'
import { useHistory } from "react-router-dom";
import ajaxLoader from '../assets/images/ajax-loader.gif'

interface User {
  name: string;
  email: string;
  id: string;
}

interface DataLogin {
  email: string;
  password: string;
}
interface AuthContextData {
  signed: boolean;
  user: User | null;
  signIn(data: DataLogin): Promise<void>;
  signInWithGoogle(data: string): Promise<void>;
  logout(): Promise<any>;
  getUserProducts(): Promise<any>;
  sendContact(data: any): Promise<void>;
  signOut(): void;
  resetPassword(data: any): Promise<void>;
  forgotPassword(data: any): Promise<void>;
  getCompanies(data: any): Promise<any>;
  getCompany(uuid: any, data: any): Promise<any>;
  getCompanyDetail(data: any): Promise<any>;
  getCompanyTeams(data: any, uuid: any): Promise<any>;
  addCompanyTeam(data: any, uuid: any): Promise<any>;
  updateCompany(data: any, uuid: any): Promise<any>;
  updateCompanyImage(data: any, uuid: any): Promise<any>;  
  sendCompany(data: any): Promise<any>;
  sendCompanyParts(data: any): Promise<any>;
  getUsers(data: any): Promise<any>;
  getUser(uuid: any, data: any): Promise<any>;
  updateUser(data: any, uuid: any): Promise<any>;
  sendUser(data: any): Promise<any>;
  deleteUser(uuid: any): Promise<any>;
  updateStatusUser(uuid: any): Promise<any>;  
  getListCompanies(): Promise<any>;
  getListRoles(data: any): Promise<any>;
  getListProducts(data: any): Promise<any>;
  getUrlSocialLogin(): Promise<any>;
  getUserAll(): Promise<any>;
  updateUserMe(data: any): Promise<any>;
  updateAccept(data: any): Promise<any>;
  getDashboard(data: any): Promise<any>;
  getDashboardCompanies(data: any): Promise<any>;
  getDashboardFormats(data: any): Promise<any>;
  getDashboardUsers(data: any): Promise<any>;
}


const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userLocalStorage, setUserLocalStorage] = useLocalStorage('@login-reanimate/user', null)
  const [tokenLocalStorage, setTokenLocalStorage] = useLocalStorage('@login-reanimate/token', null)
  const [, setProductsLocalStorage] = useLocalStorage('@login-reanimate/products', null)
  let history = useHistory();
  const [overlayView, setOverlayView] = useState('');

  const toastSuccess = (message: string, options: {}) => toast.success(message, options);

  const toastFailed = (message: any) => toast.warn(message, { position: "top-center", onClose: () => { setOverlayView('') } });

  useEffect(() => {
    if (userLocalStorage && tokenLocalStorage) {
      api.defaults.headers.Authorization = `Bearer ${tokenLocalStorage}`;
      setUser(userLocalStorage);
    }

  }, [userLocalStorage, tokenLocalStorage]);

  function cleanLocalStorage() {
    window.localStorage.removeItem('@login-reanimate/user')
    window.localStorage.removeItem('@login-reanimate/token')
    window.localStorage.removeItem('@login-reanimate/products')
    redirectTo('/')
  }

  function getErrorMessage(message: any) {

    let Msg = () => (
      <>
        Ocorreu um erro, tente novamente mais tarde
      </>
    )

    if(message !== undefined) {
      Msg = () => (
        <>
          {message?.map((item: any) => (
            <div>{item}</div>
          ))}
        </>
      )
    }

    return Msg;
  }

  async function signIn(data: DataLogin) {

    setOverlayView('active');
    const response = await auth.signIn(data);

    if (response?.status === 200) {
      const { user, access_token, products } = response.data
      setUserLocalStorage(user)
      setProductsLocalStorage(products)
      setTokenLocalStorage(access_token)

      setOverlayView('');
      toastSuccess("Seja bem-vindo!", { position: "top-center" })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }
  
  async function signInWithGoogle(accesToken: string) {

    const response = await auth.signInWithGoogle({ token: accesToken });

    if (response?.status === 200) {
      const { user, access_token, products } = response.data
      setUserLocalStorage(user)
      setProductsLocalStorage(products)
      setTokenLocalStorage(access_token)

      toastSuccess("Seja bem-vindo!", { position: "top-center" })
    } else {
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function logout() {

    const response = await auth.logout();

    if (response?.status === 200) {
      return true;
    } else {
      cleanLocalStorage()
    }

  }

  async function getUserProducts() {

    const response = await auth.getUserProducts();

    if (response?.status === 200) {
      //const { products } = response.data;
      return response.data;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function sendContact(data: any) {

    setOverlayView('active');
    const response = await auth.sendContact(data);

    if (response?.status === 200) {

      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", { position: "top-center" })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function signOut() {
  }

  async function resetPassword(data: any) {

    setOverlayView('active');
    const response = await auth.resetPassword(data);

    if (response?.status === 200) {

      setOverlayView('');
      toastSuccess("Troca de senha realizada com sucesso!", { position: "top-center" })
      redirectTo('/')
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }
  }

  async function forgotPassword(data: any) {

    setOverlayView('active');
    const response = await auth.forgotPassword(data);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Enviamos um email para recuperação da senha!", { position: "top-center" })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }
  }

  async function getCompanies(data: any = []) {

    setOverlayView('active');

    const response = await auth.getCompanies(data);

    if (response?.status === 200) {
      setOverlayView('');

      const { companies } = response.data;
      return companies;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }  

  async function getCompany(uuid: any, data: any = []) {

    setOverlayView('active');

    const response = await auth.getCompany(uuid, data);

    if (response?.status === 200) {
      setOverlayView('');

      const { company } = response.data;
      return company;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getCompanyDetail(data: any) {

    const response = await auth.getCompanyDetail(data);

    if (response?.status === 200) {

      const { company } = response.data;
      return company;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getCompanyTeams(data: any, uuid: any) {

    const response = await auth.getCompanyTeams(data, uuid);

    if (response?.status === 200) {

      const { teams } = response.data;
      return teams;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function addCompanyTeam(data: any, uuid: any) {

    setOverlayView('active');
    const response = await auth.addCompanyTeam(data, uuid);

    if (response?.status === 200) {
      setOverlayView('');
      const { team } = response.data;
      return team;
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function updateCompany(data: any, uuid: any) {

    setOverlayView('active');
    const response = await auth.updateCompany(data, uuid);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", { position: "top-center" })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function updateCompanyImage(data: any, uuid: any) {

    setOverlayView('active');
    const response = await auth.updateCompanyImage(data, uuid);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", { 
        position: "top-center",
        onClose: () => { window.location.reload() }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function sendCompany(data: any) {

    setOverlayView('active');
    const response = await auth.sendCompany(data);

    if (response?.status === 201) {

      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", {
        position: "top-center",
        onClose: () => { redirectTo('/companies') }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function sendCompanyParts(data: any) {

    setOverlayView('active');
    const response = await auth.sendCompanyParts(data);

    if (response?.status === 201) {

      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", {
        position: "top-center",
        onClose: () => { window.location.reload() }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function getUsers(data: any = []) {

    setOverlayView('active');

    const response = await auth.getUsers(data);

    if (response?.status === 200) {
      setOverlayView('');

      //const { users } = response.data;
      return response.data;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }
  
  async function getUser(uuid: any, data: any = []) {

    setOverlayView('active');

    const response = await auth.getUser(uuid, data);

    if (response?.status === 200) {
      setOverlayView('');

      const { user } = response.data;
      return user;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }
  
  async function updateUser(data: any, uuid: any) {

    setOverlayView('active');
    const response = await auth.updateUser(data, uuid);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", {
         position: "top-center",
         onClose: () => {
          window.location.reload();
        }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }
  
  async function sendUser(data: any) {

    setOverlayView('active');
    const response = await auth.sendUser(data);

    if (response?.status === 201) {
      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", {
        position: "top-center",
        onClose: () => {
          if(user['user_role']['super'] === 1)
            redirectTo('/companies')
          else if(user['user_role']['admin'] === 1 && user['user_role']['super'] === 0)
            redirectTo('/users')
        }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function deleteUser(uuid: any) {

    setOverlayView('active');
    const response = await auth.deleteUser(uuid);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Usuário excluído com sucesso!", { 
        position: "top-center",
        onClose: () => {
          window.location.reload();
        }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function updateStatusUser(uuid: any) {

    setOverlayView('active');
    const response = await auth.updateStatusUser(uuid);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Usuário alterado com sucesso!", { 
        position: "top-center",
        onClose: () => {
          window.location.reload();
        }
      })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function getListCompanies() {

    const response = await auth.getListCompanies();

    if (response?.status === 200) {
      const { companies } = response.data;
      return companies;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getListRoles(data: any = []) {

    const response = await auth.getListRoles(data);

    if (response?.status === 200) {
      const { roles } = response.data;
      return roles;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getListProducts(data: any = []) {

    const response = await auth.getListProducts(data);

    if (response?.status === 200) {
      const { products } = response.data;
      return products;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getUrlSocialLogin() {

    const response = await auth.getUrlSocialLogin();

    if (response?.status === 200) {
      const { redirect_url } = response.data;
      return redirect_url;
    } else {
      toastFailed("Não foi possível atender a solicitação!")
    }

  }  

  async function getUserAll() {

    const response = await auth.getUserAll();

    if (response?.status === 200) {
      const { user, access_token, products } = response.data
      setUserLocalStorage(user)
      setProductsLocalStorage(products)
      setTokenLocalStorage(access_token)

      //toastSuccess("Seja bem-vindo!", { position: "top-center" })
      return user;
    } else {
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function updateUserMe(data: any) {

    setOverlayView('active');
    const response = await auth.updateUserMe(data);

    if (response?.status === 200) {
      setOverlayView('');
      toastSuccess("Dados enviados com sucesso!", { position: "top-center" })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function updateAccept(data: any) {

    setOverlayView('active');
    const response = await auth.updateAccept(data);

    if (response?.status === 200) {
      const { user } = response.data
      setUserLocalStorage(user)

      setOverlayView('');
      toastSuccess("Dados salvos com sucesso!", { position: "top-center" })
    } else {
      toastFailed(getErrorMessage(response.data.errors))
    }

  }

  async function getDashboard(data: any = []) {

    setOverlayView('active');

    const response = await auth.getDashboard(data);

    if (response?.status === 200) {
      setOverlayView('');

      const { dashboard } = response.data;
      return dashboard;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getDashboardCompanies(data: any = []) {

    setOverlayView('active');

    const response = await auth.getDashboardCompanies(data);

    if (response?.status === 200) {
      setOverlayView('');

      const { companies } = response.data;
      return companies;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getDashboardFormats(data: any = []) {

    setOverlayView('active');

    const response = await auth.getDashboardFormats(data);

    if (response?.status === 200) {
      setOverlayView('');

      const { formats } = response.data;
      return formats;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  async function getDashboardUsers(data: any = []) {

    setOverlayView('active');

    const response = await auth.getDashboardUsers(data);

    if (response?.status === 200) {
      setOverlayView('');

      const { users } = response.data;
      return users;
    } else {
      cleanLocalStorage()
      toastFailed("Não foi possível realizar o login!")
    }

  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, signIn, logout, sendContact, signOut, getUserProducts, resetPassword, forgotPassword, signInWithGoogle, getCompanies, getCompany, getCompanyDetail, getCompanyTeams, addCompanyTeam, updateCompany, updateCompanyImage, sendCompany, sendCompanyParts, getUsers, getUser, updateUser, deleteUser, updateStatusUser, getListCompanies, getListRoles, getListProducts, sendUser, getUrlSocialLogin, getUserAll, updateUserMe, updateAccept, getDashboard, getDashboardCompanies, getDashboardFormats, getDashboardUsers }}
    >
      <ToastContainer />

      <div className={`bg-overlay ${overlayView}`}>
        <img src={ajaxLoader} />
      </div>

      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
