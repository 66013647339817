import * as S from './styles';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logoReanimateColors from '../../assets/images/logo-re.animate-colors.png'

import { apiRedirect } from '../../services/api'

import { Link, useLocation } from 'react-router-dom';
import React from "react";

interface FormData {
  code: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Callback = () => {
  const query = useQuery();
  const code = query.get("code") ? query.get("code") : null
  code ?? (window.location.href = '/')

  React.useEffect(() => {
    apiRedirect('auth/social/callback?code=' + code);
  },[])

  return (
    <S.Container>
      <Container fluid>
        <Row>
          <Col>
            <Row className="justify-content-center align-items-center">
              <Col className="text-center">
                <img src={logoReanimateColors} className="logo-reanimate-main" /><br />
                <p>Carregando</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </S.Container>
  );
};

export default Callback;
