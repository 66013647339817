import 'bootstrap/dist/css/bootstrap.min.css';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import { GlobalStyles } from './styles/Global';
import { AuthProvider } from './context/auth';
import Routes from './routes';

function App() {

  AOS.init()

  return (
    <AuthProvider>
      <GlobalStyles />
      <Routes />
    </AuthProvider>
  );
}

export default App;
