import * as S from './styles';

import React from "react";
import { Link, useLocation } from 'react-router-dom';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { redirectTo } from '../../utils/redirectTo'
import { useAuth } from '../../context/auth'
import api from "../../services/api";

import iconHome from '../../assets/images/icon-home.png'
import iconLogout from '../../assets/images/icon-logout.png'
import iconCompany from '../../assets/images/icon-company.png'
import iconProfile from '../../assets/images/icon-profile.png'
import iconDashboard from '../../assets/images/v2/icon-dashboard.png'

export default function Menu(props: any) {
  const { logout } = useAuth()
  const location = useLocation();  

  const [pathname, setPathname] = React.useState('/')



  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');


  React.useEffect(() => {
    setPathname( location.pathname )
  })

  const clickLogout = () => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    logout().then(res => {
      if(res === true) {
        window.localStorage.removeItem('@login-reanimate/user')
        window.localStorage.removeItem('@login-reanimate/token')
        window.localStorage.removeItem('@login-reanimate/products')
        redirectTo('/')
        //window.location.reload()
      }
    })
  }

  return (
    <>
        <header>
            <S.Menu>
                {pathname !== '/' && user['accept'] === 1 &&
                <li>
                  <Link to='/'>
                    <img src={iconHome} className="icon-home" />
                  </Link>
                </li>
                }
                {user['user_role']['super'] === 1 && user['accept'] === 1 &&
                <li>
                    <Link to='/companies'>
                      <img src={iconCompany} className="icon-company" />
                    </Link>
                </li>
                }

                {user['user_role']['super'] === 1 && user['accept'] === 1 &&
                <li>
                    <Link to='/dashboard'>
                      <img src={iconDashboard} className="icon-dashboard" />
                    </Link>
                </li>
                }

                {user['user_role']['admin'] === 1 && user['user_role']['super'] === 0 && user['accept'] === 1 &&
                <li>
                    <Link to='/users'>
                      <img src={iconCompany} className="icon-profile" />
                    </Link>
                </li>
                }
                <li onClick={clickLogout} className="btn-logout">
                  <img src={iconLogout} className="icon-logout" />
                </li>
            </S.Menu>

            {user['accept'] === 1 &&
            <S.ImgLink to='/me'>
              <img src={iconProfile} className="icon-profile-menu" />
            </S.ImgLink>
            }

          {/* 
          {user['accept'] === 1 &&
            <S.ManualLink target={'_blank'} href='https://pointmedia.s3.sa-east-1.amazonaws.com/reanimate/manual-guia-pratico-de-Re.animate.pdf'>
              Manual de Suporte
            </S.ManualLink>
          }
          */}
        </header>
    </>
  )
}