import * as S from './styles';

import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormLabel from 'react-bootstrap/FormLabel';
import Input from '../../components/Input'

import logoReanimateColors from '../../assets/images/logo-re.animate-colors-2.png'
import iconArrowLeft from '../../assets/images/icon-arrow-left-2.png'
import iconMasterMotionV2 from '../../assets/images/v2/icon-master-motion-v2.png'
import iconReplicateAdsV2 from '../../assets/images/v2/icon-replicate-ads-v2.png'
import iconEdit from '../../assets/images/v2/icon-edit.png'
import iconMask from '../../assets/images/v2/icon-mask.png'
import iconMaskRed from '../../assets/images/v2/icon-mask-red.png'
import iconDelete from '../../assets/images/v2/icon-delete.png'

import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import useReadLocalStorage from '../../hooks/useReadLocalStorage';
import { useAuth } from '../../context/auth'
import api from "../../services/api";

import ModalUserCreate from '../Companies/Modal/createUser';
import ModalUserDelete from '../Companies/Modal/deleteUser';
import ModalUserUpdate from '../Companies/Modal/updateUser';

function Users(props: any) {
  const { getUsers, updateStatusUser, getListProducts } = useAuth()
  
  const user: any = useReadLocalStorage<[]>('@login-reanimate/user');
  const token = useReadLocalStorage<[]>('@login-reanimate/token');
  const [users, setUsers] = React.useState([])
  const [unlimited, setUnlimited] = React.useState("0")
  const [parts_per_month, setPartsPerMonth] = React.useState("0")
  const [total_parts, setTotalParts] = React.useState("0")
  const [total_parts_per_month, setTotalPartsPerMonth] = React.useState("0")
  const [additional_parts, setadditionalParts] = React.useState("0")
  const [balance, setbalance] = React.useState("0")
  const [tracker, setTracker] = React.useState([])
  const [title, setTitle] = React.useState("")
  const [module, setModule] = React.useState("")
  const [months, setMonths] = React.useState("")
  const [products, setproducts] = React.useState([])
  const [listProducts, setlistProducts] = React.useState([])
  const [date_start, setDateStart] = React.useState("")
  const [date_end, setDateEnd] = React.useState("")
  const [sub, setSub] = React.useState("0")
  const [unlimited_users, setUnlimitedUsers] = React.useState("0")
  const [total_users, setTotalUsers] = React.useState("0")
  const [total_registered_users, setTotalRegisteredUsers] = React.useState("0")
  const [register_users, setRegisterUsers] = React.useState(false)

  const [show_modal, setShowModal] = React.useState(false)
  const [show_modal_delete, setShowModalDelete] = React.useState(false)
  const [user_delete, setUserDelete] = React.useState([])

  const [show_modal_update, setShowModalUpdate] = React.useState(false)

  const [user_update, setUserUpdate] = React.useState([])
  const [userUUID, setUserUUID] = React.useState('')


  React.useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;


    let usersParams:any = [];
    if(user['user_role']['super'] === 0 && user['user_role']['admin'] === 1) {
      usersParams = {"company" : user.user_companies[0].company.uuid};
    }

    getUsers(usersParams).then(res => {

      //@ts-ignore
      res.company_data.products.forEach(element => {
        //@ts-ignore
        products.push(element.product.uuid);
      });
      setproducts(products)

      setUsers(res.users);
      setTitle(user.user_companies[0].company.title);

      /* seta variáveis de paginação */
      setUnlimited(res.company_data.unlimited)
      setPartsPerMonth(res.company_data.parts_per_month)
      setTotalParts(res.company_data.total_parts)
      setTotalPartsPerMonth(res.company_data.total_parts_per_month)
      setadditionalParts(res.company_data.additional_parts)
      setbalance(res.company_data.balance)
      setTracker(res.company_data.tracker)
      setSub(res.company_data.sub)
      setUnlimitedUsers(res.company_data.unlimited_users)
      setTotalUsers(res.company_data.total_users)
      setTotalRegisteredUsers(res.company_data.total_registered_users)
      setRegisterUsers(res.company_data.register_users)

      setUserUUID(res.uuid);
    })

    getListProducts([]).then(res => {
      setlistProducts(res)
    })
  },[])

  const changeModule = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setModule(val)

    let usersParams:any = [];
    if(user['user_role']['super'] === 0 && user['user_role']['admin'] === 1) {
      usersParams = {"company" : user.user_companies[0].company.uuid};
    }

    if(val !== ''){
      //@ts-ignore
      usersParams['module'] = val;
    }

    if(months !== ''){
      //@ts-ignore
      usersParams['months'] = months;
    }
  
    if(date_start !== ''){
      //@ts-ignore
      usersParams['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      usersParams['date_end'] = date_end;
    }

    getUsers(usersParams).then(res => {
      setUsers(res.users);
      setTotalParts(res.company_data.total_parts)
      setTracker(res.company_data.tracker)
      setUserUUID(res.uuid);
    })
  }

  const changeMonths = (e: any) => {
    let val = e;
    if(typeof e.target !== 'undefined')
      val = e.target.value;

    setMonths(val)

    let usersParams:any = [];
    if(user['user_role']['super'] === 0 && user['user_role']['admin'] === 1) {
      usersParams = {"company" : user.user_companies[0].company.uuid};
    }

    if(module !== ''){
      //@ts-ignore
      usersParams['module'] = module;
    }

    if(val !== ''){
      //@ts-ignore
      usersParams['months'] = val;
    }

    if(date_start !== ''){
      //@ts-ignore
      usersParams['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      usersParams['date_end'] = date_end;
    }

    getUsers(usersParams).then(res => {
      setUsers(res.users);
      setTotalParts(res.company_data.total_parts)
      setTracker(res.company_data.tracker)
      setUserUUID(res.uuid);
    })

  }

  const handleSearch = () => {

    let usersParams:any = [];
    if(user['user_role']['super'] === 0 && user['user_role']['admin'] === 1) {
      usersParams = {"company" : user.user_companies[0].company.uuid};
    }

    if(module !== ''){
      //@ts-ignore
      usersParams['module'] = module;
    }

    if(months !== ''){
      //@ts-ignore
      usersParams['months'] = months;
    }
  
    if(date_start !== ''){
      //@ts-ignore
      usersParams['date_start'] = date_start;
    }

    if(date_end !== ''){
      //@ts-ignore
      usersParams['date_end'] = date_end;
    }

    getUsers(usersParams).then(res => {
      setUsers(res.users);
      setTotalParts(res.company_data.total_parts)
      setTracker(res.company_data.tracker)
      setUserUUID(res.uuid);
    })

  }

  const changeDateStart = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDateStart(val)
  }

  const changeDateEnd = (e: any) => {
    let val = e;

    if(val === null)
      val = '';
    else
      if(typeof e.target !== 'undefined')
        val = e.target.value;

    setDateEnd(val)
  }

  const showModal = () => {
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const showModalDelete = (user: any) => {
    setShowModalDelete(true);
    setUserDelete(user);
  };

  const hideModalDelete = () => {
    setShowModalDelete(false);
    setUserDelete([]);
  };

  const showModalUpdate = (user: any) => {
    setShowModalUpdate(true);
    setUserUpdate(user);
  };

  const hideModalUpdate = () => {
    setShowModalUpdate(false);
    setUserUpdate([]);
  };

  
  function handleClick(event: any){
    event.currentTarget.parentElement.classList.toggle('active');
  }

  const handleBack = () => {
    props.history.goBack()
  }

  return (
    <S.Container>

      <Menu />

      {show_modal === true &&
        <ModalUserCreate handleClose={hideModal} uuid={user.user_companies[0].company.uuid} />
      }

      {show_modal_delete === true &&
        <ModalUserDelete handleClose={hideModalDelete} userDelete={user_delete} />
      }

      {show_modal_update === true &&
      <ModalUserUpdate handleClose={hideModalUpdate} userUpdate={user_update} showModalDelete={showModalDelete} uuid={user.user_companies[0].company.uuid} months={months} date_start={date_start} date_end={date_end} />
      }

      <Container fluid>

        <Row>

          <Col md={12}>

            <S.UserWrapper>

              <img src={logoReanimateColors} className="logo-reanimate-colors" data-aos="zoom-in" data-aos-duration="800" />

              <Container fluid className="p-0">

                <Row>

                  <Col md={3}>

                    <h1 data-aos="fade-up" data-aos-duration="800">
                      <Link to='/' >
                        <img src={iconArrowLeft} />
                      </Link>
                      <span>{title}</span>
                    </h1>

                    <h4 className="mt-4">Módulos ativos:</h4>

                    {listProducts?.map((product: any) => (
                    <div key={product.product_id} className="product-item">
                      <label
                          id={product.product_title.toLowerCase().replace(' ', '-')}
                        className={
                          //@ts-ignore
                          (product.company_product_status === 0) ? 'disabled' : ''
                        }
                        >{product.product_title}</label>

                    </div>
                    ))}

                    <Form.Group className="mt-4">
                      <FormLabel className='info'>Produto contratado: <b>{unlimited == '1' ? 'Ilimitado' : 'Limitado' }</b></FormLabel>
                    </Form.Group>
                  
                    {unlimited == '0' &&
                    <Form.Group className="mt-1">
                      <FormLabel className='info'>Peças por mês: <b>{parts_per_month}</b></FormLabel>
                    </Form.Group>
                    }

                    <Form.Group className="mt-1">
                      <FormLabel className='info'>Total de peças criadas no mês: <b>{total_parts_per_month}</b></FormLabel>
                    </Form.Group>

                    {unlimited == '0' &&
                    <Form.Group className="mt-1">
                      <FormLabel className='info'>Saldo de peças adicionais: <b>{additional_parts}</b></FormLabel>
                    </Form.Group>
                    }

                    {unlimited == '0' &&
                    <Form.Group className="mt-1">
                      <FormLabel className='info'>Saldo de peças no mês: <b>{balance}</b></FormLabel>
                    </Form.Group>
                    }

                    {(sub == '1' && unlimited_users == '0') &&
                    <Form.Group className="mt-1">
                      <FormLabel className='info'>Total de usuários contratados: <b>{total_users}</b></FormLabel><br />
                      <FormLabel className='info'>Total de usuários cadastros: <b>{total_registered_users}</b></FormLabel>
                    </Form.Group>
                    }

                  </Col>

                  <Col md={9} className='mt-4'>


                    <Row>
                      <Col md={12} lg={9}>

                        <Form.Group className='form-filters'>
                          <FormLabel className="form-label">Filtrar por</FormLabel>

                          <input name="module" type="hidden" value={module} />
                          <select onChange={e => changeModule(e)}>
                            <option value="">Todos os módulos</option>
                            <option key={1} value='1'>Master Motion</option>
                            <option key={1} value='2'>Replicate Ads</option>
                          </select>

                          {/*
                          <input name="months" type="hidden" value={months} />
                          <select onChange={e => changeMonths(e)}>
                            <option value="">Geral</option>
                            <option value="12">Últimos 12 meses</option>
                          </select>
                          */}

                          <input name="date_start" type="date" value={date_start} onChange={e => changeDateStart(e)} />
                          <input name="date_end" type="date" value={date_end} onChange={e => changeDateEnd(e)} />
                          <div className='btn-Search' onClick={handleSearch}>Pesquisa</div>
                        </Form.Group>

                      </Col>

                      
                      <Col md={12} lg={3}>

                        {register_users == true &&
                        <Form.Group className="text-end">
                          <Link to='#' onClick={showModal} >
                            <p className='btn-new-register'>Adicionar usuário</p>
                          </Link>
                        </Form.Group>
                        }

                      </Col>
                      
                    </Row>


                    <Row>
                      <Col md={4}>                        
                        {tracker?.map((item: any) => {
                            
                            let totalItens: any = 0;
  
                            return (
                            <div className={`${item.product_title.toLowerCase().replace(' ', '-')} tracker-container`}>

                              <h3>{item.product_title}</h3>

                              {item.tracker?.map((itemTracker: any) => {

                                totalItens += itemTracker.total;

                                return (
                                <p>{itemTracker.creative} <span>{itemTracker.total}</span></p>
                              )})}

                              {item.adjustments > 0 &&
                              <p>Ajustes <span>{item.adjustments}</span></p>
                              }

                              <hr />

                              <p className="total">Total <span>{totalItens}</span></p>

                            </div>
                          )})}

                          <Form.Group>
                            <FormLabel className="general-total">Total geral <span>{total_parts}</span></FormLabel>
                          </Form.Group>
                      </Col>

                      <Col md={8}>

                        <S.UserList>

                          <Container fluid className="p-0">
                            <Row>
                              <Col md={6} lg={6} xl={6}>
                                <h4>Usuários que mais criaram</h4>
                              </Col>
                              <Col md={6} lg={6} xl={6}>
                                <h5>Peças</h5>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12} lg={12} xl={12}>
                              {users?.map((team: any) => (
                                <div className='user-list active'>
                                  <h3 onClick={handleClick}>
                                    { team?.team !== null ? team?.team : 'Padrão' }
                                  </h3>
                                  {team.users?.map((user: any) => {
                                    return (
                                    <div className={`user-list-item ${ user?.admin === 1 ? 'admin' : '' } `}>
                                      <Row>
                                        <Col md={6} lg={6} xl={6}>

                                        {userUUID !== user.uuid ?
                                          <Link to='#' key={user.id} 
                                          className={user?.status === 0 ? 'line-through' : '' }
                                          onClick={() => showModalUpdate(user)}
                                          >
                                            {user?.name}
                                          </Link>
                                        :
                                          <span className="opacity-50">{user?.name}</span>
                                        }
                                        </Col>
                                        <Col md={6} lg={6} xl={6}>
                                          <Row className='total-parts'>
                                            
                                            {module == '' &&
                                            <Col md={3} lg={3} xl={3}>
                                              {(months === '12') ? user?.total_master_motion_12 : user?.total_master_motion}
                                              <img src={iconMasterMotionV2} />
                                            </Col>
                                            }

                                            {module == '' &&
                                            <Col md={3} lg={3} xl={3}>
                                              {(months === '12') ? user?.total_replicate_ads_12 : user?.total_replicate_ads}
                                              <img src={iconReplicateAdsV2} />
                                            </Col>
                                            }

                                            {module == '1' &&
                                            <Col md={3} lg={3} xl={3}>
                                              {(months === '12') ? user?.total_master_motion_12 : user?.total_master_motion}
                                              <img src={iconMasterMotionV2} />
                                            </Col>
                                            }

                                            {module == '2' &&
                                            <Col md={3} lg={3} xl={3}>
                                              {(months === '12') ? user?.total_replicate_ads_12 : user?.total_replicate_ads}
                                              <img src={iconReplicateAdsV2} />
                                            </Col>
                                            }

                                            <Col md={6} lg={6} xl={6}>

                                              {userUUID !== user.uuid &&
                                                <div className='actions'>
                                                  <img src={iconEdit} onClick={() => showModalUpdate(user)} />
                                                  {user?.status === 1 ?
                                                    <img src={iconMask} onClick={() => updateStatusUser(user.uuid)} />
                                                    :
                                                    <img src={iconMaskRed} onClick={() => updateStatusUser(user.uuid)} />
                                                  }

                                                  <img src={iconDelete} onClick={() => showModalDelete(user)} />

                                                </div>
                                              }
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  )})}
                                </div>
                                ))}
                              </Col>
                            </Row>

                          </Container>

                        </S.UserList>


                        { /*
                          <div className="text-end">
                            <Link to='/users/create' className='btn-new-register'>
                              Cadastrar novo usuário
                            </Link>
                          </div>
                          */ }
                      </Col>
                    </Row>

                  </Col>



                </Row>

              </Container>


            </S.UserWrapper>
          </Col>
        </Row>
      </Container>
    </S.Container>
  );
};

export default Users;
